import Title from '@cohort/components-xps/components/Title';
import type {
  TypeformFormConfig,
  TypeformFormUsageData,
} from '@cohort/shared/apps/typeform/perks/form';
import {defaultErrorMessage} from '@cohort/shared/models';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';
import useNotify from '@cohort/wallet/hooks/notify';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {PerkAccessesApi} from '@cohort/wallet/lib/Endpoints';
import {Widget} from '@typeform/embed-react';
import React, {Fragment, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export const TypeformFormPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onPerkUsageSuccess,
}) => {
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'perkIntegrations.form.perkUsageComponent',
  });
  const user = useUserStore(store => store.user);
  const doUsePerkAccess = useApi(PerkAccessesApi.doUsePerkAccess);
  const notify = useNotify();
  const config = perk.integration?.config as TypeformFormConfig;

  const handleOnSubmit = useCallback(
    (payload: {responseId: string}): void => {
      doUsePerkAccess(perkAccess.id, null, {
        responseId: payload.responseId,
      } as TypeformFormUsageData)
        .then(() => {
          onPerkUsageSuccess();
        })
        .catch(err => {
          if (isCohortError(err, 'perk.usages-limit-reached')) {
            notify('error', t('maxUsagesReached'), {autoClose: false});
            return;
          }
          notify('error', defaultErrorMessage);
        });
    },
    [doUsePerkAccess, onPerkUsageSuccess, perkAccess, notify, t]
  );

  if (user === null) {
    return <NotFoundSection />;
  }

  return (
    <Fragment>
      <div className="pb-7 text-center md:text-left">
        <Title testId="use-perk-nft-picker-title" className="text-center md:text-left">
          {t('title')}
        </Title>
      </div>
      <Widget
        id={config.formId}
        inlineOnMobile
        height={550}
        style={{width: '100%'}}
        className="md:min-w-[340px] lg:min-w-[400px]"
        onSubmit={handleOnSubmit}
        hidden={{cohort_user_id: user.id}}
      />
    </Fragment>
  );
};
