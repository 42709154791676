import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {Trans, useTranslation} from 'react-i18next';

const TikTokKeywordInBioTriggerDetailsComponent: React.FC<
  DetailsComponentProps<TikTokKeywordInBioTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-bio.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div className="flex flex-row flex-wrap gap-2">
          <p>
            <Trans
              i18nKey="triggers.keyword-in-bio.detailsComponent.rulesContent"
              ns="app-tiktok"
              values={{keyword: config.keyword}}
              components={{
                keyword: <span className="font-semibold text-gray-900" />,
              }}
            />
          </p>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default TikTokKeywordInBioTriggerDetailsComponent;
