import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';

const ChallengeController = {
  protectedRoutes: [
    {
      path: 'steps/:stepId/triggers/:triggerId/use',
      lazy: () => import('@cohort/wallet/pages/challenges/TriggerUsagePage'),
    },
    {
      path: 'rewards',
      children: [
        {
          path: 'perks/:perkId',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/challenges/PerkDetailPage'),
            },
            {
              path: 'perk-accesses/:perkAccessId/use',
              lazy: () => import('@cohort/wallet/pages/challenges/PerkUsagePage'),
            },
          ],
        },
        {
          path: 'ownerships',
          children: [
            {
              path: 'imports/new',
              lazy: () => import('@cohort/wallet/pages/challenges/DigitalAssetImportPage'),
            },
            {
              path: ':ownershipId',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/challenges/DigitalAssetDetailPage'),
                },
                {
                  path: 'transfer/new',
                  lazy: () => import('@cohort/wallet/pages/challenges/NewTransferPage'),
                },
              ],
            },
          ],
        },
        {
          path: '*',
          element: <NotFoundSection />,
        },
      ],
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/challenges/ChallengeLoginPage'),
    },
  ],
};

export default ChallengeController;
