import {CurrencySchema} from '@cohort/shared/schema/common/currency';
import {z} from 'zod';

export const OrderWSchema = z.object({
  id: z.string().uuid(),
  amount: z.number().int().min(0),
  currency: CurrencySchema.nullable(),
});
export type OrderWDto = z.infer<typeof OrderWSchema>;

export const CreateOrderWSchema = z.object({
  campaignId: z.string(),
  redeemCode: z.string().optional(),
  tokenId: z.number().optional(),
});

export const PaymentSessionWSchema = z.object({
  id: z.string().uuid(),
  stripeClientSecret: z.string(),
});
export type PaymentSessionWDto = z.infer<typeof PaymentSessionWSchema>;

export const CreatePaymentSessionWSchema = z.object({
  currency: CurrencySchema,
  redeemCode: z.string().optional(),
  tokenId: z.number().optional(),
});

export const FindOrderByPaymentSessionIdDataWSchema = z.object({
  paymentSessionId: z.string(),
});
export type FindOrderByPaymentSessionIdDataWDto = z.infer<
  typeof FindOrderByPaymentSessionIdDataWSchema
>;

export const FindOrderByPaymentSessionIdResponseWSchema = z.object({
  order: OrderWSchema.nullable(),
});
export type FindOrderByPaymentSessionIdResponseWDto = z.infer<
  typeof FindOrderByPaymentSessionIdResponseWSchema
>;
