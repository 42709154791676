import type {Price} from '@cohort/shared/schema/common/currency';
import type {StoreContextType} from '@cohort/wallet/components/contexts/StoreContext';
import StoreContext from '@cohort/wallet/components/contexts/StoreContext';
import NotFoundPage from '@cohort/wallet/components/error-pages/NotFoundPage';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useStoreBySlug} from '@cohort/wallet/hooks/api/Campaign';
import {useCurrencyStore} from '@cohort/wallet/hooks/stores/currency';
import {useGeolocationStore} from '@cohort/wallet/hooks/stores/geolocation';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

type StoreContextProviderProps = {
  campaignSlug: string;
  children: React.ReactNode;
};

const useStore = (campaignSlug: string): StoreContextType | undefined | false => {
  const {geolocation} = useGeolocationStore();
  const merchant = useMerchantContext();
  const [tosChecked, setTosChecked] = useState(false);

  const {currency, setCurrency} = useCurrencyStore();

  const {search} = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const redeemCode = useMemo(() => query.get('redeemCode') ?? undefined, [query]);

  const {data: campaign, isError} = useStoreBySlug(merchant.id, campaignSlug, redeemCode);

  useEffect(() => {
    if (!campaign) {
      return;
    }
    if (campaign.store.pricing === 'free') {
      setCurrency(null);
      return;
    }
    const availableCurrencies = campaign.store.prices.map(price => price.currency);

    if (currency !== undefined && currency !== null && availableCurrencies.includes(currency)) {
      return;
    }

    let defaultCurrency = campaign.store.defaultCurrency;
    if (geolocation) {
      for (const price of campaign.store.prices) {
        if (price.currency === geolocation.currency.toLowerCase()) {
          defaultCurrency = price.currency;
        }
      }
    }
    setCurrency(defaultCurrency);
  }, [campaign, currency, geolocation, setCurrency]);

  const getPrice = (): undefined | null | Price => {
    if (campaign === undefined || currency === undefined) {
      return undefined;
    }
    if (campaign.store.pricing === 'free') {
      return null;
    }
    const price = campaign.store.prices.find(price => price.currency === currency);
    return price;
  };

  const price = getPrice();

  if (isError) {
    return false;
  }

  if (campaign === undefined || currency === undefined || price === undefined) {
    return undefined;
  }

  return {
    campaign,
    price,
    currency,
    setCurrency,
    tosChecked,
    setTosChecked,
  };
};

const StoreContextProvider = ({campaignSlug, children}: StoreContextProviderProps): JSX.Element => {
  const currentStore = useStore(campaignSlug);

  if (currentStore === false) {
    return <NotFoundPage />;
  }

  if (currentStore === undefined) {
    return <LoadingScreen />;
  }
  return <StoreContext.Provider value={currentStore}>{children}</StoreContext.Provider>;
};

export default StoreContextProvider;
