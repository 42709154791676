import type {Theme} from '@cohort/wallet/components/contexts/ThemeContext';
import ThemeContext from '@cohort/wallet/components/contexts/ThemeContext';
import React from 'react';

const useThemeContext = (): Theme => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
};

export default useThemeContext;
