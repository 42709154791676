/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {MerchantsApi} from '@cohort/wallet/lib/Endpoints';

export const merchantsKeys = {
  merchants: ['merchants'] as const,
  getByHostName: (merchantId: string) => [...merchantsKeys.merchants, merchantId] as const,
};

export const useMerchant = () => {
  const getMerchant = useApi(MerchantsApi.getMerchantBySlug);
  const slug = window.location.hostname.split('.')[0];
  if (slug === undefined) {
    throw new Error('First level subdomain is undefined');
  }
  return useCohortQuery({
    queryKey: merchantsKeys.getByHostName(slug),
    queryFn: async () => getMerchant(slug),
  });
};
