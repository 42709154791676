import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {Fragment} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

type ProtectedRoutesProps = {
  loginPath: string;
};

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({loginPath}) => {
  const {isLoggedIn, isAuthenticating} = useUserStore(
    store => ({
      isLoggedIn: store.isLoggedIn,
      isAuthenticating: store.isAuthenticating,
    }),
    shallow
  );
  const location = useLocation();

  if (isAuthenticating) {
    return <Fragment />;
  }

  if (isLoggedIn) {
    return <Outlet />;
  }

  const destinationPath = encodeURIComponent(`${location.pathname}${location.search}`);
  const redirectPath = `${loginPath}?destinationPath=${destinationPath}`;

  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoutes;
