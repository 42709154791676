import type {CohortPerkUsedTriggerStruct} from '@cohort/shared/apps/cohort/triggers/perkUsed';
import {CohortPerkUsedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/perkUsed';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortPerkUsedTriggerIntegration: TriggerIntegration<CohortPerkUsedTriggerStruct> = {
  spec: CohortPerkUsedTriggerIntegrationSpec,
  detailsComponent: null,
  actionCtaComponent: null,
  usageComponent: null,
};
