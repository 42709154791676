import type {DiscordHasRoleTriggerStruct} from '@cohort/shared/apps/discord/triggers/hasRole';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {Fragment} from 'react';

const DiscordHasRoleTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<DiscordHasRoleTriggerStruct>
> = () => {
  return <Fragment />;
};

export default DiscordHasRoleTriggerIntegrationDetailsComponent;
