import {useEffect, useState} from 'react';

export interface ScreenSize {
  width: number;
  height: number;
  isMobile: boolean;
}

const getCurrentSize = (): ScreenSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: window.innerWidth < 768,
});

export const useScreenSize = (): ScreenSize => {
  const [size, setSize] = useState<ScreenSize>(getCurrentSize());

  useEffect(() => {
    const handleResize = (): void => {
      setSize(getCurrentSize());
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};
