import {cn} from '@cohort/shared-frontend/utils/classNames';
import {WarningCircle} from '@phosphor-icons/react';

type ConnectionErrorBadgeProps = {
  label: string;
  iconSize?: 'sm' | 'md';
  textColor?: string;
};

const ConnectionErrorBadge: React.FC<ConnectionErrorBadgeProps> = ({
  label,
  iconSize = 'md',
  textColor = 'text-red-500',
}) => {
  return (
    <div className="flex items-center gap-1">
      <WarningCircle
        className={cn(textColor, iconSize === 'md' ? 'h-6 w-6' : 'h-4 w-4')}
        aria-hidden="true"
      />
      <p className={cn(textColor)}>{label}</p>
    </div>
  );
};

export default ConnectionErrorBadge;
