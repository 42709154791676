import type {Currency, Price} from '@cohort/shared/schema/common/currency';
import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import {createContext} from 'react';

export type StoreContextType = {
  campaign: CampaignStoreWDto;
  currency: Currency | null;
  setCurrency: (currency: Currency | null) => void;
  price: Price | null;
  tosChecked: boolean;
  setTosChecked: (tosChecked: boolean) => void;
};

const StoreContext = createContext<StoreContextType | undefined>(undefined);

export default StoreContext;
