import type {TypeformAppStruct} from '@cohort/shared/apps/typeform';
import {TypeformAppSpec} from '@cohort/shared/apps/typeform';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import {TypeformFormPerkIntegration} from '@cohort/wallet/apps/typeform/perkIntegrations/form/PerkIntegration';
import {TypeformFillFormTriggerIntegration} from '@cohort/wallet/apps/typeform/triggerIntegrations/fillForm/TriggerIntegration';
import {TypeformTakeQuizTriggerIntegration} from '@cohort/wallet/apps/typeform/triggerIntegrations/takeQuiz/TriggerIntegration';

const TypeformApp: App<TypeformAppStruct> = {
  spec: TypeformAppSpec,
  perkIntegrations: [TypeformFormPerkIntegration],
  triggerIntegrations: [
    TypeformFillFormTriggerIntegration,
    TypeformTakeQuizTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="typeform" height={40} width={40} />,
};

export default TypeformApp;
