import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {SpotifyFollowPlaylistTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import SpotifyFollowPlaylistTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/spotify/triggers/followPlaylist/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const SpotifyFollowPlaylistTriggerIntegration: TriggerIntegration<SpotifyFollowPlaylistTriggerStruct> =
  {
    spec: SpotifyFollowPlaylistTriggerSpec,
    detailsComponent: SpotifyFollowPlaylistTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
