import {create} from 'zustand';

export type TransitionDirection = 'left' | 'right' | 'top' | 'bottom';

export type TransitionStore = {
  active: boolean;
  enterFrom: TransitionDirection;
  startTransition: (enterFrom: TransitionDirection) => void;
  endTransition: () => void;
};

export const useTransitionStore = create<TransitionStore>(set => ({
  active: false,
  enterFrom: 'right',
  startTransition: enterFrom => set({active: true, enterFrom}),
  endTransition: () => set({active: false, enterFrom: 'right'}),
}));
