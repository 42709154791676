import {z} from 'zod';

export const MAX_MEMBER_HANDLE_LENGTH = 30;
export const MIN_MEMBER_HANDLE_LENGTH = 3;

export const MemberHandleSchema = z
  .string({
    required_error: 'Please enter a valid member handle',
    invalid_type_error: 'Please enter a valid member handle',
  })
  .max(MAX_MEMBER_HANDLE_LENGTH, 'Please enter a valid member handle')
  .min(MIN_MEMBER_HANDLE_LENGTH, 'Please enter a valid member handle')
  .regex(/^[A-Za-z0-9-]+$/u, 'Please enter a valid member handle')
  .transform(value => value.trim().toLowerCase());
