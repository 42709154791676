import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import type {SpaceSection} from '@cohort/wallet/hooks/spaceNavigation';
import useSpaceNavigation from '@cohort/wallet/hooks/spaceNavigation';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';

type BurgerItemProps = {
  isSelected: boolean;
  section: SpaceSection;
  onClick: () => void;
  disabled: boolean;
};

const BurgerItem: React.FC<BurgerItemProps> = ({section, onClick, isSelected, disabled}) => {
  const {accentColor} = useThemeContext();
  const trackingCallback = useClickTracker(`tabbar.${section.id}`);

  return (
    <li>
      <button
        disabled={disabled}
        data-testid={`burger-${section.id}`}
        onClick={() => {
          trackingCallback();
          onClick();
        }}
        className="w-full text-[--xps-p-color]"
        style={{
          ...(isSelected && {
            color: accentColor,
          }),
        }}
      >
        <div className="flex items-center gap-2">
          <section.icon
            size={24}
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          />
          <p
            className="line-clamp-2"
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          >
            {section.title}
          </p>
        </div>
      </button>
    </li>
  );
};

const SpaceBurgerNavigation: React.FC = () => {
  const navigateWithTransition = useNavigateWithTransition();
  const spaceSections = useSpaceNavigation();

  return (
    <ul className="flex w-full list-none flex-col gap-2">
      {spaceSections.map(section => (
        <BurgerItem
          key={section.id}
          section={section}
          isSelected={section.isSelected}
          disabled={section.disabled}
          onClick={() => {
            navigateWithTransition(section.path);
          }}
        />
      ))}
    </ul>
  );
};

export default SpaceBurgerNavigation;
