import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {useTranslation} from 'react-i18next';

const SpotifyFollowPlaylistTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<SpotifyFollowPlaylistTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-playlist.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div className="flex flex-wrap items-center gap-1">
          <p>{t('rulesContent')}</p>
          <p>
            <a href={config.playlistUrl} target="_blank" rel="noreferrer">
              {config.playlistName}
            </a>
          </p>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default SpotifyFollowPlaylistTriggerIntegrationDetailsComponent;
