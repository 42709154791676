import type {StoreContextType} from '@cohort/wallet/components/contexts/StoreContext';
import StoreContext from '@cohort/wallet/components/contexts/StoreContext';
import {useContext} from 'react';

const useStoreContext = (): StoreContextType => {
  const store = useContext(StoreContext);
  if (store === undefined) {
    throw new Error('useStoreContext must be used within a StoreContextProvider');
  }
  return store;
};

export default useStoreContext;
