import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';

type TrackableExternalLinkProps = JSX.IntrinsicElements['a'] & {
  tracking: TrackingConfig;
};

const TrackableExternalLink: React.FC<TrackableExternalLinkProps> = props => {
  const {tracking, ...rest} = props;
  const trackingCallback = useClickTracker(tracking.namespace, {
    ...(tracking.metadata ?? {}),
    ...{
      href: props.href,
    },
  });

  return (
    <a
      {...rest}
      onClick={e => {
        trackingCallback();
        props.onClick?.(e);
      }}
    />
  );
};

export default TrackableExternalLink;
