type DetailComponentSectionProps = {
  title: string;
  children: React.ReactElement | React.ReactElement[];
};

const DetailComponentSection: React.FC<DetailComponentSectionProps> = ({title, children}) => (
  <div className="my-2 flex flex-col gap-4">
    <h2 className="border-b pb-2">{title}</h2>
    {children}
  </div>
);

export default DetailComponentSection;
