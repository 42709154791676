import type {TikTokFollowTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/follow';
import {TikTokFollowTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/follow';
import TikTokFollowTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/follow/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokFollowTriggerIntegration: TriggerIntegration<TikTokFollowTriggerStruct> = {
  spec: TikTokFollowTriggerSpec,
  detailsComponent: null,
  actionCtaComponent: TikTokFollowTriggerActionCtaComponent,
  usageComponent: null,
};
