import type {LocalizationConfig} from '@cohort/shared/schema/common';
import {EmailSchema} from '@cohort/shared/schema/common';
import type {
  CohortFormAnswer,
  CohortFormConfig,
  CohortFormQuestion,
} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormAnswerSchema} from '@cohort/shared/schema/common/cohortForm';
import {
  buildLocalizationConfig,
  formatI18nLanguage,
  getLocalizedContentOrThrow,
} from '@cohort/shared/utils/localization';
import RichText from '@cohort/shared-frontend/components/RichText';
import Button from '@cohort/wallet/components/button/Button';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import {Input} from '@cohort/wallet/components/forms/Input';
import {SelectPicker} from '@cohort/wallet/components/forms/SelectPicker';
import {Transition} from '@cohort/wallet/components/transitions/Transition';
import {useListUserAttributes} from '@cohort/wallet/hooks/api/UserAttributes';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import dayjs from 'dayjs';
import i18n from 'i18next';
import React, {Fragment, useCallback, useState} from 'react';
import type {UseFormRegister, UseFormWatch} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {compact, uniq} from 'remeda';
import {match} from 'ts-pattern';
import {z} from 'zod';

type QuestionInputProps = {
  question: CohortFormQuestion;
  localizationConfig: LocalizationConfig;
  formErrors: Record<string, string>;
  setValue: (name: string, value: string | string[]) => void;
  register: UseFormRegister<Record<string, CohortFormAnswer>>;
  watch: UseFormWatch<Record<string, CohortFormAnswer>>;
};

const QuestionInput: React.FC<QuestionInputProps> = ({
  question,
  localizationConfig,
  formErrors,
  setValue,
  register,
  watch,
}) => {
  const localizedName = getLocalizedContentOrThrow(question.name, localizationConfig);

  const renderError = useCallback(
    (questionId: string): JSX.Element => {
      if (formErrors[questionId] === undefined) {
        return <Fragment />;
      }

      return (
        <Transition appear enterFrom="bottom" show twClasses="text-red-500 mt-1">
          <p>{formErrors[questionId]}</p>
        </Transition>
      );
    },
    [formErrors]
  );

  const value = watch(question.id);
  const error = renderError(question.id);

  return match(question.type)
    .with('text', () => (
      <Input
        name="perk-usage-input"
        type="text"
        label={localizedName}
        mandatory={question.mandatory}
        error={error}
        required={question.mandatory}
        register={register(question.id)}
      />
    ))
    .with('date', () => (
      <Input
        name="perk-usage-input"
        type="date"
        label={localizedName}
        mandatory={question.mandatory}
        error={renderError(question.id)}
        required={question.mandatory}
        register={register(question.id, {
          setValueAs: (value: unknown) => {
            const parsed = z.coerce.date().safeParse(value);
            if (!parsed.success) {
              return null;
            }
            return parsed.data;
          },
        })}
        value={value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value?.toString()}
      />
    ))
    .with('number', () => (
      <Input
        name="perk-usage-input"
        type="number"
        label={localizedName}
        mandatory={question.mandatory}
        error={error}
        required={question.mandatory}
        register={register(question.id, {
          setValueAs: (value: string) => {
            const number = parseFloat(value);
            return isNaN(number) ? null : number;
          },
        })}
      />
    ))
    .with('select', () => {
      const localizedOptions = question.options?.map(option => {
        return {
          value: option.value,
          label: getLocalizedContentOrThrow(option.label, localizationConfig),
        };
      });
      if (localizedOptions === undefined) {
        throw new Error('No options for select');
      }

      if (question.multipleChoice) {
        if (value !== null && !Array.isArray(value)) {
          setValue(question.id, []);
          return <Fragment />;
        }
        return (
          <SelectPicker
            label={localizedName}
            mandatory={question.mandatory}
            error={error}
            value={localizedOptions.filter(option => value?.includes(option.value))}
            options={localizedOptions}
            onChange={newValue =>
              setValue(
                question.id,
                newValue.map(option => option.value)
              )
            }
            isMulti={true}
          />
        );
      }
      return (
        <SelectPicker
          label={localizedName}
          mandatory={question.mandatory}
          error={error}
          value={localizedOptions.find(option => option.value === value)}
          options={localizedOptions}
          onChange={newValue => setValue(question.id, newValue?.value ?? '')}
        />
      );
    })
    .with('checkbox', () => (
      <CheckboxInput
        name="perk-usage-input"
        label={localizedName}
        mandatory={question.mandatory}
        error={error}
        required={question.mandatory}
        register={register(question.id)}
      />
    ))
    .with('email', () => (
      <Input
        name="perk-usage-email-input"
        type="email"
        label={localizedName}
        mandatory={question.mandatory}
        error={error}
        required={question.mandatory}
        register={register(question.id)}
      />
    ))
    .exhaustive();
};

type WrappedCohortFormComponentProps = {
  config: CohortFormConfig;
  onSubmit: (values: Record<string, CohortFormAnswer>) => void;
  isLoading: boolean;
  onSubmitTracking: TrackingConfig;
  userAttributes: Array<UserAttributeWDto>;
};

const WrappedCohortFormComponent: React.FC<WrappedCohortFormComponentProps> = ({
  config,
  onSubmit,
  isLoading,
  onSubmitTracking,
  userAttributes,
}) => {
  const merchant = useMerchantContext();
  const {t} = useTranslation('components', {keyPrefix: 'forms.cohortForm'});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  // include user attributes in default values
  const defaultValues: Record<string, CohortFormAnswer> = {};
  for (const question of config.questions) {
    const userAttributeValue = userAttributes.find(
      attribute => attribute.userPropertyId === question.userPropertyId
    )?.value;

    const answerFromUserAttribute = userAttributeValue
      ? CohortFormAnswerSchema.parse(userAttributeValue)
      : null;
    defaultValues[question.id] = answerFromUserAttribute ?? '';

    if (question.type === 'checkbox') {
      defaultValues[question.id] = false;
    }

    if (question.type === 'select' && question.multipleChoice) {
      defaultValues[question.id] = [];
    }
  }

  const {
    register,
    handleSubmit: onFormSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues,
  });

  const localizationConfig = buildLocalizationConfig(
    formatI18nLanguage(i18n.language),
    merchant.supportedLanguages,
    merchant.defaultLanguage
  );

  const validateForm = (data: Record<string, CohortFormAnswer>): void => {
    const values: Record<string, CohortFormAnswer> = {};
    const submitErrors: Record<string, string> = {};
    for (const question of config.questions) {
      values[question.id] = data[question.id] ?? '';
      const answer = data[question.id];
      const emptyAnswer =
        answer === '' ||
        answer === undefined ||
        answer === null ||
        (Array.isArray(answer) && answer.length === 0);

      if (question.mandatory && emptyAnswer) {
        submitErrors[question.id] = t('required');
      }

      if (question.type === 'email' && !emptyAnswer) {
        try {
          EmailSchema.parse(data[question.id] ?? '');
        } catch {
          submitErrors[question.id] = t('invalidEmail');
        }
      }

      if (question.type === 'checkbox' && question.mandatory && emptyAnswer) {
        submitErrors[question.id] = t('checkboxRequired');
      }
    }

    if (Object.keys(submitErrors).length > 0) {
      setFormErrors(submitErrors);
      return;
    }
    onSubmit(values);
  };

  return (
    <Fragment>
      <RichText
        html={getLocalizedContentOrThrow(config.description, localizationConfig)}
        className="mt-2"
      />
      <form className="mt-8 flex max-w-3xl flex-col gap-8 px-[1px]">
        <div className="flex flex-col gap-5">
          {config.questions.map(question => (
            <QuestionInput
              question={question}
              localizationConfig={localizationConfig}
              key={question.id}
              formErrors={formErrors}
              setValue={setValue}
              register={register}
              watch={watch}
            />
          ))}
        </div>
        <Button
          variant="primary"
          testId="perk-form-submit"
          className="w-full"
          onClick={onFormSubmit(validateForm)}
          loading={isLoading}
          tracking={onSubmitTracking}
        >
          {t('submit')}
        </Button>
      </form>
    </Fragment>
  );
};

type CohortFormComponentProps = {
  config: CohortFormConfig;
  onSubmit: (values: Record<string, CohortFormAnswer>) => void;
  isLoading: boolean;
  onSubmitTracking: TrackingConfig;
};

const CohortFormComponent: React.FC<CohortFormComponentProps> = ({
  config,
  onSubmit,
  isLoading,
  onSubmitTracking,
}) => {
  const userPropertyIds = uniq(compact(config.questions.map(question => question.userPropertyId)));
  const {data: userAttributes} = useListUserAttributes(userPropertyIds);

  if (userAttributes === undefined) {
    return <Fragment />;
  }

  // Refresh the form at user attributes change
  const refreshKey = userAttributes.map(attribute => attribute.value).join();

  return (
    <WrappedCohortFormComponent
      key={refreshKey}
      config={config}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onSubmitTracking={onSubmitTracking}
      userAttributes={userAttributes}
    />
  );
};
export default CohortFormComponent;
