import type {InstagramTagInPostTriggerStruct} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {useTranslation} from 'react-i18next';

const InstagramTagInPostTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<InstagramTagInPostTriggerStruct>
> = () => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.tag-in-post.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <p>{t('rulesContent')}</p>
      </DetailComponentSection>
    </div>
  );
};

export default InstagramTagInPostTriggerIntegrationDetailsComponent;
