import Title from '@cohort/components-xps/components/Title';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort/triggers/fillForm';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import CohortFormComponent from '@cohort/wallet/components/forms/CohortForm';
import {useTranslation} from 'react-i18next';

const CohortFillFormUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFillFormTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.fill-form',
  });

  return (
    <div className="space-y-7">
      <Title className="text-center md:text-left">{t('title')}</Title>
      <CohortFormComponent
        config={config}
        onSubmit={data => onTriggerIntegrationUsageSuccess({answers: data})}
        isLoading={false}
        onSubmitTracking={{
          namespace: 'trigger.completion',
          metadata: {
            appId: 'cohort',
            action: 'trigger.fillForm',
          },
        }}
      />
    </div>
  );
};

export default CohortFillFormUsageComponent;
