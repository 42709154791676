import {getConfig, getIcon} from '@cohort/wallet/components/toasts/Toast';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {useCallback} from 'react';
import type {Id, ToastOptions, TypeOptions} from 'react-toastify';
import {toast} from 'react-toastify';

type CustomToastOptions = ToastOptions & {description?: React.ReactNode};

export default function useNotify(): (
  type: TypeOptions,
  message: unknown,
  options?: CustomToastOptions
) => Id {
  const {hasDarkBg} = useThemeContext();

  return useCallback(
    (type: TypeOptions, message: unknown, options?: CustomToastOptions) => {
      const errorMessage = message instanceof Error ? message.message : message;

      const content = (
        <div className="flex flex-col space-y-1 pb-2 pt-1">
          <p className="font-medium">{errorMessage as React.ReactNode}</p>
          {options?.description && <p className="text-xs opacity-70">{options.description}</p>}
        </div>
      );

      return toast(content, {
        toastId: 'toast',
        ...options,
        type,
        icon: getIcon(type),
        ...getConfig(hasDarkBg),
      });
    },
    [hasDarkBg]
  );
}
