import type {ShopifyDiscountPerkIntegrationStruct} from '@cohort/shared/apps/shopify/perks/discount';
import {ShopifyDiscountPerkIntegrationSpec} from '@cohort/shared/apps/shopify/perks/discount';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';
import {ShopifyDiscountPerkUsageComponent} from '@cohort/wallet/apps/shopify/perkIntegrations/discount/PerkUsageComponent';

export const ShopifyDiscountPerkIntegration: PerkIntegration<ShopifyDiscountPerkIntegrationStruct> =
  {
    spec: ShopifyDiscountPerkIntegrationSpec,
    usageComponent: ShopifyDiscountPerkUsageComponent,
  };
