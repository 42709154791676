import MerchantLogoPlaceholder from '@cohort/components-xps/components/MerchantLogoPlaceholder';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import React from 'react';

type StoreLogoProps = {
  logoImageFileKey: string | null;
  name: string;
};

const StoreLogo: React.FC<StoreLogoProps> = ({logoImageFileKey, name}): React.ReactElement => {
  return (
    <div className="flex items-center">
      {logoImageFileKey === null ? (
        <div className="mr-3">
          <MerchantLogoPlaceholder merchantName={name} size={36} />
        </div>
      ) : (
        <img
          data-testid="store-logo"
          title={`${name} logo`}
          className="mr-3 inline-block h-9 w-9 min-w-[36px] rounded-full border md:h-9 md:w-9"
          src={getImageUrl(import.meta.env.COHORT_ENV, logoImageFileKey, {
            h: Sizes.XS,
            w: Sizes.XS,
          })}
        />
      )}
    </div>
  );
};

export default StoreLogo;
