import type {ChallengeContextType} from '@cohort/wallet/components/contexts/ChallengeContext';
import ChallengeContext from '@cohort/wallet/components/contexts/ChallengeContext';
import {useContext} from 'react';

const useChallengeContext = (): ChallengeContextType => {
  const context = useContext(ChallengeContext);
  if (context === undefined) {
    throw new Error('useChallengeContext must be used within a ChallengeContextProvider');
  }
  return context;
};

export default useChallengeContext;
