import type {FacebookTagInPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {FacebookTagInPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import FacebookTagInPostTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/tagInPost/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookTagInPostTriggerIntegration: TriggerIntegration<FacebookTagInPostTriggerStruct> =
  {
    spec: FacebookTagInPostTriggerSpec,
    detailsComponent: FacebookTagInPostTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
