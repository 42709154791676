import SpaceContext from '@cohort/wallet/components/contexts/SpaceContext';
import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useMerchantSpace} from '@cohort/wallet/hooks/api/Space';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {Outlet} from 'react-router-dom';

const SpaceContextProvider = (): JSX.Element => {
  const user = useUserStore(store => store.user);
  const {data: space, isError} = useMerchantSpace(user !== null);

  if (isError) {
    return <NotFoundSection />;
  }

  if (space === undefined) {
    return <LoadingScreen />;
  }

  return (
    <SpaceContext.Provider value={space}>
      <Outlet />
    </SpaceContext.Provider>
  );
};

export default SpaceContextProvider;
