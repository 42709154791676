import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {InstagramCommentMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import InstagramCommentMediaTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/commentMedia/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramCommentMediaTriggerIntegration: TriggerIntegration<InstagramCommentMediaTriggerStruct> =
  {
    spec: InstagramCommentMediaTriggerIntegrationSpec,
    detailsComponent: InstagramCommentMediaTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
