import {cn} from '@cohort/shared-frontend/utils/classNames';
import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import type {SpaceSection} from '@cohort/wallet/hooks/spaceNavigation';
import useSpaceNavigation from '@cohort/wallet/hooks/spaceNavigation';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';

type SpaceTabbarButtonProps = {
  isSelected: boolean;
  section: SpaceSection;
  onClick: () => void;
  disabled: boolean;
};

const SpaceTabbarButton: React.FC<SpaceTabbarButtonProps> = ({
  section,
  onClick,
  isSelected,
  disabled,
}) => {
  const {accentColor} = useThemeContext();
  const trackingCallback = useClickTracker(`tabbar.${section.id}`);

  return (
    <button
      disabled={disabled}
      data-testid={`tab-${section.id}`}
      onClick={() => {
        trackingCallback();
        onClick();
      }}
      className="w-full border-0 px-1 text-center text-xs font-medium text-[--xps-p-color]"
    >
      <div className="flex justify-center">
        <section.icon
          size={24}
          style={{
            color: isSelected ? accentColor : undefined,
          }}
        />
      </div>
      <p
        className="line-clamp-2"
        style={{
          color: isSelected ? accentColor : undefined,
        }}
      >
        {section.title}
      </p>
    </button>
  );
};

const SpaceTabbar = (): JSX.Element => {
  const navigateWithTransition = useNavigateWithTransition();
  const spaceSections = useSpaceNavigation();

  return (
    <nav
      className={cn('bottom-0 z-50 flex h-[var(--mobile-bottom-navbar-height)] w-screen border-t')}
      aria-label="Tabs"
    >
      {spaceSections.map(section => (
        <SpaceTabbarButton
          key={section.id}
          section={section}
          isSelected={section.isSelected}
          disabled={section.disabled}
          onClick={() => {
            navigateWithTransition(section.path);
          }}
        />
      ))}
    </nav>
  );
};

export default SpaceTabbar;
