// i18nOwl-ignore [app-youtube.userDataPolicy]
import type {YoutubeAppStruct} from '@cohort/shared/apps/youtube';
import {YOUTUBE_APP_ID, YoutubeAppSpec} from '@cohort/shared/apps/youtube';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import {YoutubeCommentTriggerIntegration} from '@cohort/wallet/apps/youtube/triggers/comment/TriggerIntegration';
import {YoutubeLikeTriggerIntegration} from '@cohort/wallet/apps/youtube/triggers/like/TriggerIntegration';
import {YoutubeSubscribeTriggerIntegration} from '@cohort/wallet/apps/youtube/triggers/subscribe/TriggerIntegration';

const YoutubeApp: App<YoutubeAppStruct> = {
  spec: YoutubeAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(YOUTUBE_APP_ID),
    YoutubeCommentTriggerIntegration,
    YoutubeLikeTriggerIntegration,
    YoutubeSubscribeTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="youtube" height={40} width={40} />,
  withUserDataPolicy: true,
};

export default YoutubeApp;
