import MerchantContext from '@cohort/wallet/components/contexts/MerchantContext';
import NotFoundPage from '@cohort/wallet/components/error-pages/NotFoundPage';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useMerchant} from '@cohort/wallet/hooks/api/Merchants';
import {useLDClient} from 'launchdarkly-react-client-sdk';

type MerchantContextProviderProps = {
  children: React.ReactNode;
};

const MerchantContextProvider: React.FC<MerchantContextProviderProps> = ({children}) => {
  const {data: merchant, isFetched} = useMerchant();
  const ldClient = useLDClient();

  if (isFetched && !merchant) {
    return <NotFoundPage />;
  }

  if (merchant === undefined) {
    return <LoadingScreen />;
  }

  if (ldClient !== undefined) {
    ldClient.identify({
      key: merchant.organization.slug,
      kind: 'organization',
    });
  }

  return <MerchantContext.Provider value={merchant}>{children}</MerchantContext.Provider>;
};

export default MerchantContextProvider;
