import type {StravaAppStruct} from '@cohort/shared/apps/strava';
import {STRAVA_APP_ID, StravaAppSpec} from '@cohort/shared/apps/strava';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import {StravaBeActiveTriggerIntegration} from '@cohort/wallet/apps/strava/triggerIntegrations/beActive/TriggerIntegration';
import {StravaCompleteActivityTriggerIntegration} from '@cohort/wallet/apps/strava/triggerIntegrations/completeActivity/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const StravaApp: App<StravaAppStruct> = {
  spec: StravaAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(STRAVA_APP_ID),
    StravaBeActiveTriggerIntegration,
    StravaCompleteActivityTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="strava" height={40} width={40} />,
};

export default StravaApp;
