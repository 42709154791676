import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TypeformTakeQuizTriggerIntegrationActionCtaComponent from '@cohort/wallet/apps/typeform/triggerIntegrations/takeQuiz/ActionCtaComponent';
import TypeformTakeQuizUsageComponent from '@cohort/wallet/apps/typeform/triggerIntegrations/takeQuiz/UsageComponent';

export const TypeformTakeQuizTriggerIntegration: TriggerIntegration<TypeformTakeQuizTriggerStruct> =
  {
    spec: TypeformTakeQuizTriggerIntegrationSpec,
    detailsComponent: null,
    actionCtaComponent: TypeformTakeQuizTriggerIntegrationActionCtaComponent,
    usageComponent: TypeformTakeQuizUsageComponent,
  };
