import Button from '@cohort/wallet/components/button/Button';
import ErrorPageTemplate from '@cohort/wallet/components/error-pages/ErrorPageTemplate';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {XCircle} from '@phosphor-icons/react';
import {ErrorBoundary} from '@sentry/react';
import {useTranslation} from 'react-i18next';

type ErrorComponentProps = {
  resetError: () => void;
};

const ErrorComponent: React.FC<ErrorComponentProps> = ({resetError}) => {
  const {t} = useTranslation('components', {keyPrefix: 'errorPages.errorBoundary'});
  const {logoFileKey} = useMerchantContext();
  const {accentColor, backgroundColor} = useThemeContext();

  return (
    <ErrorPageTemplate
      title={t('title')}
      description={t('description')}
      Icon={XCircle}
      accentColor={accentColor}
      backgroundColor={backgroundColor}
      logoFileKey={logoFileKey ?? undefined}
      cta={
        <Button
          variant="primary"
          className="w-full"
          onClick={resetError}
          tracking={{namespace: 'errorBoundary.back'}}
        >
          {t('btnBack')}
        </Button>
      }
    />
  );
};

const ErrorBoundaryComponent = ({children}: {children: React.ReactNode}): JSX.Element => (
  <ErrorBoundary fallback={props => <ErrorComponent {...props} />}>{children}</ErrorBoundary>
);

export default ErrorBoundaryComponent;
