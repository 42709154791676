import type {ConnectAccountTriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export function createConnectAccountTriggerIntegration<Id extends string>(
  appId: Id
): TriggerIntegration<ConnectAccountTriggerIntegrationStruct<`${Id}.connect-account`>> {
  return {
    spec: createConnectAccountTriggerIntegrationSpec(appId),
    detailsComponent: null,
    actionCtaComponent: null,
    usageComponent: null,
  };
}
