import type {InstagramTagInPostTriggerStruct} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import {InstagramTagInPostTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import InstagramTagInPostTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/tagInPost/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramTagInPostTriggerIntegration: TriggerIntegration<InstagramTagInPostTriggerStruct> =
  {
    spec: InstagramTagInPostTriggerIntegrationSpec,
    detailsComponent: InstagramTagInPostTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
