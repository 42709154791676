import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import {createContext} from 'react';

export type Theme = {
  setCampaignTheme: (campaign: CampaignStoreWDto) => void;
  backgroundColor: string;
  accentColor: string;
  hasDarkBg: boolean;
  hasDarkAccent: boolean;
  themeLoaded: boolean;
};

const ThemeContext = createContext<Theme | undefined>(undefined);

export default ThemeContext;
