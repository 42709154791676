import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {Colors} from '@cohort/wallet/lib/Theme';
import type {Icon as IconType} from '@phosphor-icons/react';

export type ErrorSectionTemplateProps = {
  Icon?: IconType;
  title: string;
  description: string;
  cta?: JSX.Element;
  accentColor?: string;
  backgroundColor?: string;
};

const ErrorSectionTemplate: React.FC<ErrorSectionTemplateProps> = ({
  Icon,
  title,
  description,
  cta,
  accentColor,
  backgroundColor,
}) => {
  const textColor =
    !backgroundColor || (backgroundColor && !isDark(backgroundColor)) ? Colors.Black : Colors.White;
  const iconColor = accentColor ?? 'var(--cohort-color)';

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        {Icon && (
          <Icon
            size={64}
            style={{
              color: iconColor,
            }}
            weight="duotone"
          />
        )}
        <h1 style={{color: textColor}}>{title}</h1>
        <p className={cn(isDark(textColor) ? 'text-black/80' : 'text-white/80')}>{description}</p>
        {cta}
      </div>
    </div>
  );
};

export default ErrorSectionTemplate;
