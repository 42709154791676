import i18n from '@cohort/components-xps/lib/i18n';
import {
  getStravaActivityTypeLabel,
  StravaTriggerIntegrationActivityTypesMappedByGroups,
} from '@cohort/shared/apps/strava/activity';
import type {StravaCompleteActivityTriggerStruct} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {toKilometers, toKilometersPerHour, toMinutes} from '@cohort/shared/utils/convert';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {toUserDate} from '@cohort/wallet/lib/Utils';
import {useTranslation} from 'react-i18next';

const StravaCompleteActivityTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<StravaCompleteActivityTriggerStruct>
> = ({config}) => {
  const currentLanguage = formatI18nLanguage(i18n.language);
  const {t} = useTranslation('components', {
    keyPrefix: 'triggerIntegrations.completeActivity.detailsComponent',
  });

  const startDate = new Date(config.activityStartDateTimestamp);
  const endDate = new Date(config.activityEndDateTimestamp);

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('eligibleActivitiesTitle')}>
        <div className="flex flex-row flex-wrap gap-2">
          {config.activityGroups ? (
            config.activityGroups
              .flatMap(group => StravaTriggerIntegrationActivityTypesMappedByGroups[group])
              .map(activityType => {
                return (
                  <div className="inline-flex items-center whitespace-nowrap rounded-md bg-gray-500 px-2.5 py-0.5 text-sm font-medium text-white">
                    {getStravaActivityTypeLabel(activityType, currentLanguage)}
                  </div>
                );
              })
          ) : (
            <p>{t('eligibleActivitiesIsNullContent')}</p>
          )}
        </div>
      </DetailComponentSection>

      <DetailComponentSection title={t('dateRangeTitle')}>
        <div className="flex flex-row gap-1">
          <p>{t('startDateLabel')}</p>
          <p>{toUserDate(startDate)}</p>
          <p>{t('endDateLabel')}</p>
          <p>{toUserDate(endDate)}</p>
        </div>
      </DetailComponentSection>

      {config.minDistanceMeters && (
        <DetailComponentSection title={t('distanceTitle')}>
          <p>{toKilometers(config.minDistanceMeters)} km</p>
        </DetailComponentSection>
      )}

      {config.minDurationSeconds && (
        <DetailComponentSection title={t('durationTitle')}>
          <p>{toMinutes(config.minDurationSeconds)} minutes</p>
        </DetailComponentSection>
      )}

      {config.minAverageElevationGainMeters && (
        <DetailComponentSection title={t('elevationTitle')}>
          <p>{config.minAverageElevationGainMeters} m</p>
        </DetailComponentSection>
      )}

      {config.minAverageSpeedMetersPerSecond && (
        <DetailComponentSection title={t('speedTitle')}>
          <p>{toKilometersPerHour(config.minAverageSpeedMetersPerSecond)} km/h</p>
        </DetailComponentSection>
      )}
    </div>
  );
};

export default StravaCompleteActivityTriggerIntegrationDetailComponent;
