import type {WebappLanguage} from '@cohort/shared/schema/common';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import type {OwnershipWDto} from '@cohort/wallet-schemas/ownership';
import type {PerkAccessWDto} from '@cohort/wallet-schemas/perkAccess';
import dayjs from 'dayjs';
import i18n from 'i18next';

export type SectionName = 'description' | 'rewards' | 'faq';
export type SigninMethod = 'google' | 'email' | 'auth-token';
export interface AccessesAndOwnership {
  access: PerkAccessWDto;
  ownership: OwnershipWDto;
}

export const GRAY_500 = '#6b7280';
export const STYLE_TITLE_CARD = 'text-base font-medium';
export const STYLE_SUBTITLE_CARD = 'text-sm font-normal';

export const SCROLL_SPY_CONTAINER_ID = 'scroll-container';

export async function poll<T extends unknown[], R>(
  fn: (...args: T) => Promise<R>,
  interval: number,
  maxTries: number,
  ...args: T
): Promise<R> {
  return new Promise((resolve, reject) => {
    let tries = 0;
    const intervalId = setInterval(async () => {
      try {
        const result = await fn(...args);
        clearInterval(intervalId);
        resolve(result);
      } catch (err: unknown) {
        tries++;
        if (tries >= maxTries) {
          clearInterval(intervalId);
          reject(err);
        }
      }
    }, interval);
  });
}

export function languageToLocale(language: string): WebappLanguage {
  switch (language) {
    case 'fr-FR':
    case 'fr':
      return 'fr';
    case 'en-EN':
    case 'en-US':
    case 'en-GB':
    case 'en':
      return 'en';
    default:
      return 'en';
  }
}

export const nftObtentionDate = (ownership: OwnershipWDto): string => {
  const {t} = i18n;

  if (ownership.activeDigitalAssetTransferInvitation?.status === 'active') {
    return t('transferredSubtitle', {
      ns: 'utils',
      receiverEmail: ownership.activeDigitalAssetTransferInvitation.recipientEmail,
    });
  }

  const obtentionType =
    ownership.obtentionType === 'transfer'
      ? t('imported', {ns: 'utils'})
      : t('ordered', {ns: 'utils'});

  return `${obtentionType} ${dayjs(ownership.createdAt).fromNow()}`;
};

export const perkObtentionDate = (ownership: OwnershipWDto, canBeUsed: boolean): string => {
  const {t} = i18n;
  const perkStatusText = canBeUsed ? t('ordered', {ns: 'utils'}) : t('used', {ns: 'utils'});

  return `${perkStatusText} ${dayjs(ownership.createdAt).fromNow()}`;
};

const pad = (num: number): string => num.toString().padStart(2, '0');

/**
 * Formats a number of seconds into a human readable time.
 * formatDuration(5000) => 01h 23m 20s
 */
export const formatDuration = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  let time = `${pad(s)}s`;
  if (m > 0 || h > 0) {
    time = `${pad(m)}m ${time}`;
  }
  if (h > 0) {
    time = `${pad(h)}h ${time}`;
  }
  return time;
};

export const toUserDate = (date: Date): string => {
  return [
    date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    }),
  ].join(', ');
};

export function assert<T>(value: unknown): value is T {
  if (value === undefined) {
    throw new Error('Assertion failed');
  }
  return true;
}

export function getCohortTosUrl(): string {
  const currentLanguage = formatI18nLanguage(i18n.language);

  if (currentLanguage === 'fr') {
    return 'https://storage.googleapis.com/cohort-prod-347206.appspot.com/terms-fr.pdf';
  }
  return 'https://getcohort.com/terms-of-service';
}

export function getCohortPrivacyUrl(): string {
  return 'https://getcohort.com/privacy-policy';
}

export const isOnSpace = (): boolean => {
  const rootPath = window.location.pathname.split('/')[1];
  const isSpaceRoute = rootPath === 'space';
  return isSpaceRoute;
};
