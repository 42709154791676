import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {DatetimeSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const PerkUsageInputWSchema = z.object({
  userConnectionId: z.string().nullable(),
  data: z.unknown(),
});
export type PerkUsageInputWDto = z.infer<typeof PerkUsageInputWSchema>;

export const PerkUsageIntegrationWSchema = z.object({
  perkIntegrationId: PerkIntegrationIdSchema,
  data: z.unknown(),
});
export type PerkUsageIntegrationWDto = z.infer<typeof PerkUsageIntegrationWSchema>;

export const PerkUsageWSchema = z.object({
  id: z.string().uuid(),
  usedAt: DatetimeSchema,
  integration: PerkUsageIntegrationWSchema.nullable(),
});
export type PerkUsageWDto = z.infer<typeof PerkUsageWSchema>;
