import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import {FacebookLikePostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePost';
import FacebookLikePostTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/likePost/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookLikePostTriggerIntegration: TriggerIntegration<FacebookLikePostTriggerStruct> =
  {
    spec: FacebookLikePostTriggerSpec,
    detailsComponent: FacebookLikePostTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
