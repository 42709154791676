/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ConnectorId} from '@cohort/shared/apps';
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {ConnectionsApi} from '@cohort/wallet/lib/Endpoints';

export const appsKeys = {
  apps: ['apps'] as const,
  appConnection: (connectorId: ConnectorId) => [...appsKeys.apps, connectorId] as const,
};

export const useAppConnection = (connectorId: ConnectorId, enabled?: boolean) => {
  const getAppConnection = useApi(ConnectionsApi.getConnectionForConnector);
  return useCohortQuery({
    queryKey: appsKeys.appConnection(connectorId),
    queryFn: async () => getAppConnection(connectorId),
    select: data => data.connection,
    enabled,
  });
};
