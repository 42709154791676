import {asArray} from '@cohort/shared/schema/common';
import {parseValue} from '@cohort/shared/schema/common/userAttribute';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const UserAttributeWSchema = z
  .object({
    id: z.string().uuid(),
    userPropertyId: z.string().uuid(),
    dataType: UserPropertyDataTypeSchema,
    value: z.unknown(),
  })
  .transform(data => {
    const value = parseValue(data.dataType, data.value);
    return {
      ...data,
      value,
    };
  });
export type UserAttributeWDto = z.infer<typeof UserAttributeWSchema>;

// List User Properties
export const ListUserAttributesParamsWSchema = z.object({
  userPropertyIds: z.preprocess(asArray, z.array(z.string()).optional()),
});
export type ListUserAttributesParamsWDto = z.infer<typeof ListUserAttributesParamsWSchema>;

export const ListUserAttributesResponseWSchema = z.array(UserAttributeWSchema);
export type ListUserAttributesResponseWDto = z.infer<typeof ListUserAttributesResponseWSchema>;
