import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {Colors} from '@cohort/wallet/lib/Theme';
import Color from 'color';
import React from 'react';
import {useTranslation} from 'react-i18next';

const TestModeBanner: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'testMode.testModeBanner'});
  const {accentColor, hasDarkAccent} = useThemeContext();
  const merchant = useMerchantContext();

  return (
    <div>
      {merchant.mode === 'test' && (
        <div
          className="w-full p-2 text-center text-sm"
          style={{
            backgroundColor: accentColor,
            color: String(Color(hasDarkAccent ? Colors.White : Colors.Black)),
          }}
        >
          {t('title')}
        </div>
      )}
    </div>
  );
};

export default TestModeBanner;
