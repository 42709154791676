import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import CohortApp from '@cohort/wallet/apps/cohort/CohortApp';
import DiscordApp from '@cohort/wallet/apps/discord/DiscordApp';
import FacebookApp from '@cohort/wallet/apps/facebook/FacebookApp';
import GarminConnectApp from '@cohort/wallet/apps/garmin-connect/GarminConnectApp';
import GoogleFitApp from '@cohort/wallet/apps/google-fit/GoogleFitApp';
import InstagramApp from '@cohort/wallet/apps/instagram/InstagramApp';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';
import ShopifyApp from '@cohort/wallet/apps/shopify/ShopifyApp';
import SpotifyApp from '@cohort/wallet/apps/spotify/SpotifyApp';
import StravaApp from '@cohort/wallet/apps/strava/StravaApp';
import TalonOneApp from '@cohort/wallet/apps/talon-one/TaloneOneApp';
import TikTokApp from '@cohort/wallet/apps/tiktok/TikTokApp';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TwitterApp from '@cohort/wallet/apps/twitter/TwitterApp';
import TypeformApp from '@cohort/wallet/apps/typeform/TypeformApp';
import YoutubeApp from '@cohort/wallet/apps/youtube/YoutubeApp';
import type React from 'react';

export type CustomOAuthErrorMessage = (
  cause: CohortErrorCode
) => {title: string; description: string} | undefined;

export type App<T extends AppStruct = AppStruct> = {
  spec: AppSpec<T>;
  perkIntegrations: ReadonlyArray<PerkIntegration>;
  triggerIntegrations: ReadonlyArray<TriggerIntegration>;
  logo: React.ReactElement | null;
  withUserDataPolicy?: boolean;
  customOauthErrorMessage?: CustomOAuthErrorMessage;
};

export const Apps = [
  CohortApp,
  DiscordApp,
  FacebookApp,
  GarminConnectApp,
  GoogleFitApp,
  InstagramApp,
  ShopifyApp,
  SpotifyApp,
  StravaApp,
  TalonOneApp,
  TikTokApp,
  TwitterApp,
  TypeformApp,
  YoutubeApp,
] satisfies ReadonlyArray<App>;
