import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {trackEvent} from '@cohort/wallet/lib/Tracking';
import {useCallback} from 'react';

export default function useClickTracker(
  namespace: TrackingConfig['namespace'],
  metadata?: Record<string, unknown>
): () => void {
  return useCallback(() => trackEvent(`${namespace}.click`, metadata), [namespace, metadata]);
}
