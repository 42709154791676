import {useEffect, useState} from 'react';

const useDelayedBoolean = (delay: number): boolean => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setValue(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return value;
};

export default useDelayedBoolean;
