import type {DiscordHasRoleTriggerStruct} from '@cohort/shared/apps/discord/triggers/hasRole';
import {DiscordHasRoleTriggerIntegrationSpec} from '@cohort/shared/apps/discord/triggers/hasRole';
import DiscordHasRoleTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/discord/triggerIntegrations/hasRole/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const DiscordHasRoleTriggerIntegration: TriggerIntegration<DiscordHasRoleTriggerStruct> = {
  spec: DiscordHasRoleTriggerIntegrationSpec,
  detailsComponent: DiscordHasRoleTriggerIntegrationDetailsComponent,
  actionCtaComponent: null,
  usageComponent: null,
};
