import type {YoutubeSubscribeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {YoutubeSubscribeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/subscribe';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const YoutubeSubscribeTriggerIntegration: TriggerIntegration<YoutubeSubscribeTriggerStruct> =
  {
    spec: YoutubeSubscribeTriggerSpec,
    detailsComponent: null,
    actionCtaComponent: null,
    usageComponent: null,
  };
