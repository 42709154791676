import type {TikTokAppStruct} from '@cohort/shared/apps/tiktok';
import {TIKTOK_APP_ID, TikTokAppSpec} from '@cohort/shared/apps/tiktok';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import {TikTokCommentTiktokTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/commentTiktok/TriggerIntegration';
import {TikTokFollowTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/follow/TriggerIntegration';
import {TikTokKeywordInBioTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/keywordInBio/TriggerIntegration';
import {TikTokKeywordInDescriptionTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/keywordInDescription/TriggerIntegration';
import {TikTokLikeTiktokTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/likeTiktok/TriggerIntegration';
import {TikTokRepostTiktokTriggerIntegration} from '@cohort/wallet/apps/tiktok/triggers/repostTiktok/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const TikTokApp: App<TikTokAppStruct> = {
  spec: TikTokAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(TIKTOK_APP_ID),
    TikTokFollowTriggerIntegration,
    TikTokLikeTiktokTriggerIntegration,
    TikTokCommentTiktokTriggerIntegration,
    TikTokRepostTiktokTriggerIntegration,
    TikTokKeywordInBioTriggerIntegration,
    TikTokKeywordInDescriptionTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="tiktok" height={40} width={40} />,
};

export default TikTokApp;
