import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {TikTokLikeTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import TikTokLikeTiktokTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/likeTiktok/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokLikeTiktokTriggerIntegration: TriggerIntegration<TikTokLikeTiktokTriggerStruct> =
  {
    spec: TikTokLikeTiktokTriggerSpec,
    detailsComponent: null,
    actionCtaComponent: TikTokLikeTiktokTriggerActionCtaComponent,
    usageComponent: null,
  };
