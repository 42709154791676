import {z} from 'zod';

export const GeolocationSchema = z.object({
  continent: z.string(),
  continentCode: z.string(),
  country: z.string(),
  countryCode: z.string(),
  currency: z.string(),
  timezone: z.string(),
});
export type Geolocation = z.infer<typeof GeolocationSchema>;
