import type {StravaCompleteActivityTriggerStruct} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {StravaCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/completeActivity';
import StravaCompleteActivityTriggerIntegrationDetailComponent from '@cohort/wallet/apps/strava/triggerIntegrations/completeActivity/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const StravaCompleteActivityTriggerIntegration: TriggerIntegration<StravaCompleteActivityTriggerStruct> =
  {
    spec: StravaCompleteActivityTriggerIntegrationSpec,
    detailsComponent: StravaCompleteActivityTriggerIntegrationDetailComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
