import Button from '@cohort/wallet/components/button/Button';
import {ArrowsClockwise} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const NewVersionToast: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'toasts.newVersionToast'});

  return (
    <div
      className="flex flex-col items-center"
      style={{
        fontFamily: 'var(--xps-font-family)',
      }}
    >
      <span className="mb-2 text-sm font-medium">{t('title')}</span>
      <Button
        variant="primary"
        onClick={() => window.location.reload()}
        className="h-10"
        tracking={{namespace: 'toasts.newVersion.update'}}
      >
        <ArrowsClockwise className="h-5 w-5" />
        {t('updateBtn')}
      </Button>
    </div>
  );
};

export default NewVersionToast;
