import SpaceContextProvider from '@cohort/wallet/components/providers/SpaceContextProvider';
import {Navigate} from 'react-router-dom';

const SpaceController = {
  protectedRoutes: [
    {
      element: <SpaceContextProvider />,
      children: [
        {index: true, element: <Navigate to="/space/home" replace />},
        {
          path: 'home',
          lazy: () => import('@cohort/wallet/pages/space/HomePage'),
        },
        {
          path: 'badges',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/BadgesListPage'),
            },
          ],
        },
        {
          path: 'challenges',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/ChallengeListPage'),
            },
            {
              path: ':challengeSlug',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/space/ChallengeDetailPage'),
                },
                {
                  path: 'steps/:stepId/triggers/:triggerId/use',
                  lazy: () => import('@cohort/wallet/pages/space/TriggerUsagePage'),
                },
              ],
            },
          ],
        },
        {
          path: 'rewards',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/RewardListPage'),
            },
            {
              path: 'perks/:perkId',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/space/PerkDetailPage'),
                },
                {
                  path: 'perk-accesses/:perkAccessId/use',
                  lazy: () => import('@cohort/wallet/pages/space/PerkUsagePage'),
                },
              ],
            },
            {
              path: 'ownerships',
              children: [
                {
                  path: 'imports/new',
                  lazy: () => import('@cohort/wallet/pages/space/DigitalAssetImportPage'),
                },
                {
                  path: ':ownershipId',
                  children: [
                    {
                      index: true,
                      lazy: () => import('@cohort/wallet/pages/space/DigitalAssetDetailPage'),
                    },
                    {
                      path: 'transfer/new',
                      lazy: () => import('@cohort/wallet/pages/space/NewTransferPage'),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'settings',
          lazy: () => import('@cohort/wallet/pages/space/settings/SettingsPage'),
        },
      ],
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/space/MerchantSpaceLoginPage'),
    },
    {
      path: 'invitations/:invitationId/login',
      lazy: () => import('@cohort/wallet/pages/space/DigitalAssetTransferInvitationLoginPage'),
    },
  ],
  bothPublicAndProtectedRoutes: [
    {
      path: 'ownerships/:ownershipId/confirm-transfer',
      lazy: () => import('@cohort/wallet/pages/space/DigitalAssetTransferRequestPage'),
    },
    {
      path: 'invitations/:invitationId',
      lazy: () => import('@cohort/wallet/pages/space/DigitalAssetTransferInvitationPage'),
    },
  ],
};

export default SpaceController;
