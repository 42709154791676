import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {Fragment} from 'react';
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

type PublicRoutesProps = {
  defaultRedirectPath: string;
};

const PublicRoutes: React.FC<PublicRoutesProps> = ({defaultRedirectPath}) => {
  const [searchParams] = useSearchParams();
  const {isLoggedIn, isAuthenticating} = useUserStore(
    store => ({
      isLoggedIn: store.isLoggedIn,
      isAuthenticating: store.isAuthenticating,
    }),
    shallow
  );

  if (isAuthenticating) {
    return <Fragment />;
  }

  if (isLoggedIn) {
    const destinationPath = searchParams.get('destinationPath');

    if (destinationPath !== null) {
      return <Navigate to={destinationPath} />;
    }
    return <Navigate to={defaultRedirectPath} replace />;
  }
  return <Outlet />;
};

export default PublicRoutes;
