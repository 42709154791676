import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';
import FacebookLikePageTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/likePage/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookLikePageTriggerIntegration: TriggerIntegration<FacebookLikePageTriggerStruct> =
  {
    spec: FacebookLikePageTriggerSpec,
    detailsComponent: FacebookLikePageTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
