import Button from '@cohort/wallet/components/button/Button';
import type {ActionCtaTrakingMetadata} from '@cohort/wallet/components/space/ActionCtaComponentWrapper';

type OpenUrlActionCtaButtonProps = {
  url: string;
  text: string;
  processStepVerification: () => void;
  trackingData: ActionCtaTrakingMetadata;
};

const OpenUrlActionCtaButton: React.FC<OpenUrlActionCtaButtonProps> = ({
  url,
  text,
  processStepVerification,
  trackingData,
}) => {
  return (
    <Button
      variant="primary"
      size="small"
      className="w-full md:w-auto"
      type="button"
      tracking={{
        namespace: 'challenges.detail.actionCta',
        metadata: trackingData,
      }}
      onClick={() => {
        window.open(url, '_blank');
        processStepVerification();
      }}
    >
      {text}
    </Button>
  );
};

export default OpenUrlActionCtaButton;
