import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {TikTokKeywordInBioTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import TikTokKeywordInBioTriggerDetailsComponent from '@cohort/wallet/apps/tiktok/triggers/keywordInBio/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokKeywordInBioTriggerIntegration: TriggerIntegration<TikTokKeywordInBioTriggerStruct> =
  {
    spec: TikTokKeywordInBioTriggerSpec,
    detailsComponent: TikTokKeywordInBioTriggerDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
