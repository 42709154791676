import {NetworkSchema} from '@cohort/shared/schema/common/blockchain';
import {z} from 'zod';

export const SmartContractWSchema = z.object({
  network: NetworkSchema,
  address: z.string(),
  blockExplorerUrl: z.string().url().nullable(),
});
export type SmartContractWDto = z.infer<typeof SmartContractWSchema>;

export const DigitalAssetCollectionWSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string(),
  imageKey: z.string(),
  animationKey: z.string().nullable(),
  smartContract: SmartContractWSchema.nullable(),
  maxOwnershipsPerUser: z.number().nullable(),
});
export type DigitalAssetCollectionWDto = z.infer<typeof DigitalAssetCollectionWSchema>;
