// i18nOwl-ignore [app-spotify.userDataPolicy]
import type {SpotifyAppStruct} from '@cohort/shared/apps/spotify';
import {SPOTIFY_APP_ID, SpotifyAppSpec} from '@cohort/shared/apps/spotify';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import {SpotifyFollowPlaylistTriggerIntegration} from '@cohort/wallet/apps/spotify/triggers/followPlaylist/TriggerIntegration';
import {SpotifyFollowUserTriggerIntegration} from '@cohort/wallet/apps/spotify/triggers/followUser/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const SpotifyApp: App<SpotifyAppStruct> = {
  spec: SpotifyAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(SPOTIFY_APP_ID),
    SpotifyFollowPlaylistTriggerIntegration,
    SpotifyFollowUserTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="spotify" height={40} width={40} />,
  withUserDataPolicy: true,
};

export default SpotifyApp;
