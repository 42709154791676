import Button from '@cohort/wallet/components/button/Button';
import ErrorSectionTemplate from '@cohort/wallet/components/error-pages/ErrorSectionTemplate';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {WarningOctagon} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const NotFoundSection: React.FC = () => {
  const {accentColor, backgroundColor} = useThemeContext();
  const navigate = useNavigate();
  const {t} = useTranslation('components', {keyPrefix: 'errorPages.notFoundPage'});

  return (
    <ErrorSectionTemplate
      title={t('title')}
      description={t('description')}
      Icon={WarningOctagon}
      accentColor={accentColor}
      backgroundColor={backgroundColor}
      cta={
        <Button
          variant="primary"
          className="w-full"
          onClick={() => navigate(-1)}
          tracking={{namespace: 'notfound.back'}}
        >
          {t('btnBack')}
        </Button>
      }
    />
  );
};

export default NotFoundSection;
