import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {TypeformFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/fillForm';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TypeformFillFormTriggerIntegrationActionCtaComponent from '@cohort/wallet/apps/typeform/triggerIntegrations/fillForm/ActionCtaComponent';
import TypeformFillFormUsageComponent from '@cohort/wallet/apps/typeform/triggerIntegrations/fillForm/UsageComponent';

export const TypeformFillFormTriggerIntegration: TriggerIntegration<TypeformFillFormTriggerStruct> =
  {
    spec: TypeformFillFormTriggerIntegrationSpec,
    detailsComponent: null,
    actionCtaComponent: TypeformFillFormTriggerIntegrationActionCtaComponent,
    usageComponent: TypeformFillFormUsageComponent,
  };
