import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import {CohortVisitLinkTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/visitLink';
import CohortVisitLinkTriggerIntegrationActionCtaComponent from '@cohort/wallet/apps/cohort/triggerIntegrations/visitLink/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortVisitLinkTriggerIntegration: TriggerIntegration<CohortVisitLinkTriggerStruct> = {
  spec: CohortVisitLinkTriggerIntegrationSpec,
  detailsComponent: null,
  actionCtaComponent: CohortVisitLinkTriggerIntegrationActionCtaComponent,
  usageComponent: null,
};
