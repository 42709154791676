import type {BaseModalProps} from '@cohort/wallet/components/modals/BaseModal';
import BaseModal from '@cohort/wallet/components/modals/BaseModal';
import type {DrawerModalProps} from '@cohort/wallet/components/modals/DrawerModal';
import DrawerModal from '@cohort/wallet/components/modals/DrawerModal';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';

type ModalOrDrawerProps = {
  children: React.ReactNode;
  tracking: TrackingConfig;
  onClose: () => void;
  modalProps?: Partial<BaseModalProps>;
  drawerProps?: Partial<DrawerModalProps>;
};

const ModalOrDrawer: React.FC<ModalOrDrawerProps> = ({
  children,
  tracking,
  onClose,
  modalProps,
  drawerProps,
}) => {
  const {isMobile} = useScreenSize();

  if (isMobile) {
    return (
      <DrawerModal tracking={tracking} onClose={onClose} {...drawerProps}>
        {children}
      </DrawerModal>
    );
  }
  return (
    <BaseModal tracking={tracking} onClose={onClose} {...modalProps}>
      {children}
    </BaseModal>
  );
};
export default ModalOrDrawer;
