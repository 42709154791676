import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import type {ErrorSectionTemplateProps} from '@cohort/wallet/components/error-pages/ErrorSectionTemplate';
import ErrorSectionTemplate from '@cohort/wallet/components/error-pages/ErrorSectionTemplate';
import SvgIcon from '@cohort/wallet/components/Svg';
import {Fragment} from 'react';

type ErrorPageTemplateProps = ErrorSectionTemplateProps & {
  logoFileKey?: string;
};

const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({logoFileKey, ...props}) => (
  <div className="flex h-screen flex-row">
    <div className="flex w-full items-center justify-center md:w-1/3">
      <ErrorSectionTemplate {...props} />
    </div>
    <div className="relative hidden flex-1 md:block">
      <div
        className={cn(
          'flex h-full items-center justify-center',
          props.backgroundColor
            ? isDark(props.backgroundColor)
              ? 'bg-white/8'
              : 'bg-black/4'
            : undefined
        )}
      >
        {logoFileKey ? (
          <img
            className="z-10"
            width={100}
            src={getImageUrl(import.meta.env.COHORT_ENV, logoFileKey, {
              h: Sizes.M,
              w: Sizes.M,
            })}
          />
        ) : (
          <Fragment>
            <SvgIcon name="CohortErrorBg" className="absolute min-h-full min-w-full" />
            <SvgIcon name="CohortLogo" height={50} className="z-10" />
          </Fragment>
        )}
      </div>
    </div>
  </div>
);

export default ErrorPageTemplate;
