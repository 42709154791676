import {Transition} from '@headlessui/react';

const FadeInBlock: React.FC<{appear?: boolean; show: boolean; children: React.ReactNode}> = ({
  appear,
  show,
  children,
}) => (
  <Transition
    appear={appear}
    show={show}
    enter="transition-opacity duration-500"
    enterFrom="opacity-0"
    enterTo="opacity-100"
  >
    {children}
  </Transition>
);

export default FadeInBlock;
