import type {TwitterAppStruct} from '@cohort/shared/apps/twitter';
import {TWITTER_APP_ID, TwitterAppSpec} from '@cohort/shared/apps/twitter';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import {TwitterFollowTriggerIntegration} from '@cohort/wallet/apps/twitter/triggerIntegrations/follow/TriggerIntegration';

const TwitterApp: App<TwitterAppStruct> = {
  spec: TwitterAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(TWITTER_APP_ID),
    TwitterFollowTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="twitter" height={40} width={40} />,
};

export default TwitterApp;
