import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Transition as HeadlessUiTransition} from '@headlessui/react';
import React, {useMemo} from 'react';

type TransitionDirection = 'top' | 'bottom' | 'left' | 'right';

interface TransitionProps {
  children: JSX.Element | JSX.Element[];
  enterFrom: TransitionDirection;
  leaveTo?: TransitionDirection;
  isChild?: boolean;
  enterDuration?: string;
  leaveDuration?: string;
  enterDelay?: string;
  leaveDelay?: string;
  twClasses?: string;
  appear?: boolean;
  show?: boolean;
}

export const Transition = ({
  children,
  enterFrom,
  leaveTo,
  isChild = false,
  enterDuration,
  leaveDuration,
  enterDelay,
  leaveDelay,
  twClasses,
  appear,
  show,
}: TransitionProps): React.ReactElement => {
  const enterDirection = useMemo(() => {
    switch (enterFrom) {
      case 'top':
        return '-translate-y-5';
      case 'bottom':
        return 'translate-y-5';
      case 'left':
        return '-translate-x-5';
      case 'right':
        return 'translate-x-5';
    }
  }, [enterFrom]);

  const leaveDirection = useMemo(() => {
    switch (leaveTo) {
      case 'top':
        return 'translate-y-5';
      case 'bottom':
        return '-translate-y-5';
      case 'left':
        return 'translate-x-5';
      case 'right':
        return '-translate-x-5';
      default:
        return '';
    }
  }, [leaveTo]);

  const ConditionalWrapper = useMemo(
    () => (isChild ? HeadlessUiTransition.Child : HeadlessUiTransition),
    [isChild]
  );

  return (
    <ConditionalWrapper
      show={show ?? true}
      appear={appear ?? false}
      className={twClasses}
      enter={cn(
        'transition ease-out transform',
        enterDuration !== undefined ? enterDuration : 'duration-500',
        enterDelay
      )}
      enterFrom={cn('opacity-0', enterDirection)}
      enterTo="opacity-100"
      leave={cn(
        'transition-opacity',
        leaveDuration !== undefined ? leaveDuration : 'duration-0',
        leaveDelay
      )}
      leaveFrom="opacity-100"
      leaveTo={cn('opacity-0', leaveDirection)}
    >
      {children}
    </ConditionalWrapper>
  );
};
Transition.displayName = 'Transition';
