import type {ConnectorId} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';

export const OAuthErrorList = [
  'connector.insufficient-permissions',
  'oauth.access-denied',
  'connector.credentials-invalid',
  'oauth.authorization-error-retryable',
  'oauth.authorization-error-unretryable',
] as const;
export type OAuthErrorCode = (typeof OAuthErrorList)[number];
export type OAuthError = {
  connectorId: ConnectorId;
  errorCode: OAuthErrorCode;
  cause: CohortErrorCode | undefined;
};

export const oauthErrorTranslationMapping: Record<OAuthErrorCode, string> = {
  'connector.insufficient-permissions': 'errorInsufficientPermissions',
  'oauth.access-denied': 'errorAccessDenied',
  'connector.credentials-invalid': 'errorDefault',
  'oauth.authorization-error-retryable': 'errorDefault',
  'oauth.authorization-error-unretryable': 'errorDefault',
};
