/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {SpacesApi} from '@cohort/wallet/lib/Endpoints';

export const spacesKeys = {
  spaces: ['spaces'] as const,
  getSpace: () => [...spacesKeys.spaces] as const,
  syncOwnerships: () => [...spacesKeys.spaces, 'sync-ownerships'] as const,
};

export const useMerchantSpace = (enabled: boolean) => {
  const getMerchantSpace = useApi(SpacesApi.getMerchantSpace);
  return useCohortQuery({
    queryKey: spacesKeys.getSpace(),
    queryFn: async () => getMerchantSpace(),
    enabled,
  });
};

export const useSyncSpaceOwnerships = (refetchInterval: number | false, enabled?: boolean) => {
  const syncSpaceOwnerships = useApi(SpacesApi.syncSpaceOwnerships);
  return useCohortQuery({
    queryKey: spacesKeys.syncOwnerships(),
    queryFn: async () => syncSpaceOwnerships(),
    refetchInterval,
    enabled,
  });
};
