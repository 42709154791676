import i18n from '@cohort/components-xps/lib/i18n';
import {
  GarminConnectTriggerIntegrationActivityTypesMappedByGroups,
  getGarminConnectActivityTypeLabel,
} from '@cohort/shared/apps/garmin-connect/activity';
import type {GarminConnectBeActiveTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {toKilometers, toMinutes} from '@cohort/shared/utils/convert';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {toUserDate} from '@cohort/wallet/lib/Utils';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const GarminConnectBeActiveTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<GarminConnectBeActiveTriggerStruct>
> = ({config}) => {
  const currentLanguage = formatI18nLanguage(i18n.language);
  const {t} = useTranslation('components', {
    keyPrefix: 'triggerIntegrations.beActive.detailsComponent',
  });

  const startDate = new Date(config.startDateTimestamp);
  const endDate = new Date(config.endDateTimestamp);
  const unit = match(config.metric)
    .with('distance', () => 'km')
    .with('duration', () => 'min')
    .with('elevation', () => 'm')
    .exhaustive();

  const metricTitle = match(config.metric)
    .with('distance', () => t('distanceTitle'))
    .with('duration', () => t('durationTitle'))
    .with('elevation', () => t('elevationTitle'))
    .exhaustive();

  const value = match(config.metric)
    .with('distance', () => toKilometers(config.value))
    .with('duration', () => toMinutes(config.value))
    .with('elevation', () => config.value)
    .exhaustive();

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('eligibleActivitiesTitle')}>
        <div className="flex flex-row flex-wrap gap-2">
          {config.activityGroups ? (
            config.activityGroups
              .flatMap(group => GarminConnectTriggerIntegrationActivityTypesMappedByGroups[group])
              .map(activityType => {
                return (
                  <div className="inline-flex items-center whitespace-nowrap rounded-md bg-gray-500 px-2.5 py-0.5 text-sm font-medium text-white">
                    {getGarminConnectActivityTypeLabel(activityType, currentLanguage)}
                  </div>
                );
              })
          ) : (
            <p>{t('eligibleActivitiesIsNullContent')}</p>
          )}
        </div>
      </DetailComponentSection>

      <DetailComponentSection title={t('dateRangeTitle')}>
        <div className="flex flex-row gap-1">
          <p>{t('startDateLabel')}</p>
          <p>{toUserDate(startDate)}</p>
          <p>{t('endDateLabel')}</p>
          <p>{toUserDate(endDate)}</p>
        </div>
      </DetailComponentSection>

      <DetailComponentSection title={metricTitle}>
        <p>
          {value} {unit}
        </p>
      </DetailComponentSection>
    </div>
  );
};

export default GarminConnectBeActiveTriggerIntegrationDetailComponent;
