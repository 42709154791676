import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import OpenUrlActionCtaButton from '@cohort/wallet/apps/OpenUrlActionCtaButton';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {useTranslation} from 'react-i18next';

const TikTokRepostTiktokTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<TikTokRepostTiktokTriggerStruct>
> = ({config, processStepVerification, trackingData}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.repost-tiktok',
  });

  return (
    <OpenUrlActionCtaButton
      url={config.url}
      text={t('actionButton')}
      processStepVerification={processStepVerification}
      trackingData={trackingData}
    />
  );
};

export default TikTokRepostTiktokTriggerActionCtaComponent;
