import type {CampaignChallengeWDto} from '@cohort/wallet-schemas/campaign';
import type {OwnershipWDto} from '@cohort/wallet-schemas/ownership';
import type {PerkSpaceViewWDto} from '@cohort/wallet-schemas/perkAccess';
import {createContext} from 'react';

export type ChallengeContextType = {
  campaign: CampaignChallengeWDto;
  ownerships: OwnershipWDto[];
  perks: PerkSpaceViewWDto[];
};

const ChallengeContext = createContext<ChallengeContextType | undefined>(undefined);

export default ChallengeContext;
