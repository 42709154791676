import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {trackEvent} from '@cohort/wallet/lib/Tracking';
import {useEffect, useRef} from 'react';

export default function usePageViewTracker(
  namespace: TrackingConfig['namespace'],
  metadata?: Record<string, unknown>
): void {
  const hasTracked = useRef(false);

  useEffect(() => {
    // Only track once per page view.
    if (hasTracked.current) {
      return;
    }
    trackEvent(`${namespace}.view`, metadata);
    hasTracked.current = true;
  }, [namespace, metadata]);
}
