import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import OpenUrlActionCtaButton from '@cohort/wallet/apps/OpenUrlActionCtaButton';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {useTranslation} from 'react-i18next';

const CohortVisitLinkTriggerIntegrationActionCtaComponent: React.FC<
  ActionCtaComponentProps<CohortVisitLinkTriggerStruct>
> = ({config, processStepVerification, trackingData}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.visit-link',
  });

  return (
    <OpenUrlActionCtaButton
      url={config.url}
      text={t('actionButton')}
      processStepVerification={processStepVerification}
      trackingData={trackingData}
    />
  );
};

export default CohortVisitLinkTriggerIntegrationActionCtaComponent;
