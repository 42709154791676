import type {InputProps} from '@cohort/wallet/components/forms/Input';
import React from 'react';

type DefaultInput = Omit<JSX.IntrinsicElements['input'], 'type'>;

export const CheckboxInput: React.FC<DefaultInput & InputProps> = inputProps => {
  const {testId, register, label, mandatory, error} = inputProps;

  return (
    <div className="flex flex-col">
      <div className="flex">
        <label className="cursor-pointer">
          <input
            data-testid={testId}
            type="checkbox"
            {...register}
            className="h-4 w-4 cursor-pointer rounded border border-[--xps-input-border-color] bg-[--xps-input-background-color] text-[--xps-accent-color] focus:outline-none focus:ring-0 focus:ring-offset-0"
          />
          <span className="ml-2 text-sm">{label}</span>
          {mandatory && <span> *</span>}
        </label>
      </div>
      {error}
    </div>
  );
};
