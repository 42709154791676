import type {Language} from '@cohort/shared/schema/common';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import type {User} from 'firebase/auth';
import {create} from 'zustand';

export type UserStore = {
  firebaseUser: User | null;
  initEmail?: string;
  isAuthenticating: boolean;
  isLoggedIn: boolean;
  user: UserWDto | null;
  localizationLoaded: boolean;
  login: (firebaseUser: User, user: UserWDto) => void;
  logout: () => void;
  setIsAuthenticating: (isAuthenticating: boolean) => void;
  setInitEmail: (email?: string) => void;
  setLocalizationLoaded: (localizationLoaded: boolean) => void;
  setUserLanguage: (language: Language) => void;
  setUserNotifications: (notificationsEnabled: boolean) => void;
  setUserHandle: (handle: string) => void;
};

export const useUserStore = create<UserStore>(set => ({
  firebaseUser: null,
  isAuthenticating: true,
  isLoggedIn: false,
  user: null,
  localizationLoaded: false,
  login: (firebaseUser, user) =>
    set({
      isAuthenticating: false,
      isLoggedIn: true,
      firebaseUser,
      user,
    }),
  logout: () =>
    set({
      isAuthenticating: false,
      isLoggedIn: false,
      firebaseUser: null,
      user: null,
    }),
  setIsAuthenticating: isAuthenticating => set({isAuthenticating}),
  setInitEmail: email => set({initEmail: email}),
  setLocalizationLoaded: localizationLoaded => set({localizationLoaded}),
  setUserLanguage: lang =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          lang,
        },
      };
    }),
  setUserNotifications: notificationsEnabled =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          notificationsEnabled,
        },
      };
    }),
  setUserHandle: handle =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          membershipPass: {
            ...state.user.membershipPass,
            memberHandle: handle,
          },
        },
      };
    }),
}));
