import {
  getChallengesListRoute,
  getMerchantSpaceRoute,
  getRewardsListRoute,
  getSpaceSettingsRoute,
} from '@cohort/wallet/lib/Pages';
import type {CaretLeft} from '@phosphor-icons/react';
import {Flag, GearSix, Gift, HouseLine} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

export type SpaceSection = {
  id: string;
  title: string;
  // We take a random icon from the lib to get the proper type
  icon: typeof CaretLeft;
  isSelected: boolean;
  disabled: boolean;
  path: string;
};

export default function useSpaceNavigation(): Array<SpaceSection> {
  const {t} = useTranslation('hooks', {keyPrefix: 'spaceNavigation'});
  const location = useLocation();

  function isCurrentPath(href: string): boolean {
    return location.pathname === href;
  }

  return [
    {
      id: 'home',
      title: t('homePageTitle'),
      icon: HouseLine,
      isSelected: location.pathname.startsWith(getMerchantSpaceRoute()),
      disabled: isCurrentPath(getMerchantSpaceRoute()),
      path: getMerchantSpaceRoute(),
    },
    {
      id: 'challenges',
      title: t('challengesTitle'),
      icon: Flag,
      isSelected: location.pathname.startsWith(getChallengesListRoute()),
      disabled: isCurrentPath(getChallengesListRoute()),
      path: getChallengesListRoute(),
    },
    {
      id: 'rewards',
      title: t('rewardsTitle'),
      icon: Gift,
      isSelected: location.pathname.startsWith('/space/rewards'),
      disabled: isCurrentPath(getRewardsListRoute()),
      path: getRewardsListRoute(),
    },
    {
      id: 'settings',
      title: t('settingsTitle'),
      icon: GearSix,
      isSelected: location.pathname.startsWith(getSpaceSettingsRoute()),
      disabled: isCurrentPath(getSpaceSettingsRoute()),
      path: getSpaceSettingsRoute(),
    },
  ];
}
