import {CustomComponentTypeSchema} from '@cohort/shared/schema/common/customComponents';
import {z} from 'zod';

export const CustomComponentWSchema = z.object({
  id: z.string().uuid(),
  type: CustomComponentTypeSchema,
  template: z.string(),
});

export type CustomComponentWDto = z.infer<typeof CustomComponentWSchema>;
