import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import {YoutubeCommentTriggerSpec} from '@cohort/shared/apps/youtube/triggers/comment';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import YoutubeCommentTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/youtube/triggers/comment/DetailsComponent';

export const YoutubeCommentTriggerIntegration: TriggerIntegration<YoutubeCommentTriggerStruct> = {
  spec: YoutubeCommentTriggerSpec,
  detailsComponent: YoutubeCommentTriggerIntegrationDetailsComponent,
  actionCtaComponent: null,
  usageComponent: null,
};
