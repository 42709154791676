import type {Geolocation} from '@cohort/shared/schema/common/geolocation';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type GeolocationStore = {
  geolocation: Geolocation | null | undefined;
  setGeolocation: (geolocation: Geolocation | null) => void;
};

export const useGeolocationStore = create<GeolocationStore>()(
  persist(
    set => ({
      geolocation: undefined,
      setGeolocation: geolocation => set({geolocation}),
    }),
    {
      name: 'geolocation',
    }
  )
);
