import type {ConnectorId} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import type {OAuthError, OAuthErrorCode} from '@cohort/wallet/lib/oAuth';
import {OAuthErrorList} from '@cohort/wallet/lib/oAuth';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

export default function useOauthError(): [
  OAuthError | undefined,
  (error: OAuthError | undefined) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [oauthError, setOauthError] = useState<OAuthError | undefined>(() => {
    if (searchParams.has('oauthError') && searchParams.has('oauthConnectorId')) {
      const errorCode = searchParams.get('oauthError') as OAuthErrorCode;
      const cause = searchParams.get('oauthErrorCause') as CohortErrorCode | undefined;
      const connectorId = searchParams.get('oauthConnectorId') as ConnectorId;

      return OAuthErrorList.includes(errorCode)
        ? ({
            connectorId: connectorId,
            cause: cause,
            errorCode,
          } satisfies OAuthError)
        : undefined;
    }
    return undefined;
  });

  useEffect(() => {
    if (searchParams.has('oauthError') && searchParams.has('oauthConnectorId')) {
      searchParams.delete('oauthError');
      searchParams.delete('oauthConnectorId');
      searchParams.delete('oauthErrorCause');
      setSearchParams(searchParams, {replace: true});
    }
  }, [searchParams, setSearchParams]);

  return [oauthError, setOauthError] as const;
}
