import {useTransitionStore} from '@cohort/wallet/hooks/stores/transition';
import type {NavigateOptions, To} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

type TransitionDirection = 'backward' | 'forward';
export type NavigateFunctionWithTransition = {
  (to: To | number, options?: NavigateOptions, direction?: TransitionDirection): void;
};

export const useNavigateWithTransition = (): NavigateFunctionWithTransition => {
  const {startTransition} = useTransitionStore();
  const navigate = useNavigate();

  function navigateWithTransition(
    to: To | number,
    options?: NavigateOptions,
    direction?: TransitionDirection
  ): void {
    if (direction === 'backward') {
      startTransition('right');
    } else {
      startTransition('left');
    }
    if (typeof to === 'number') {
      return navigate(to);
    } else {
      navigate(to, options);
    }
  }

  return navigateWithTransition;
};
