import type {ActionStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

// Execute Action
export const ExecuteActionDataWSchema = z.object({
  appId: z.string(),
  actionId: z.string(),
  merchantConnectionId: z.string().nullable(),
  userConnectionId: z.string().nullable(),
  input: z.unknown(),
});
export type ExecuteActionDataWDto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteActionDataWSchema
> & {
  actionId: T['Id'];
  input: T['Input'];
};

export const ExecuteActionResponseWSchema = z.object({
  output: z.unknown(),
});
export type ExecuteActionResponseWDto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteActionResponseWSchema
> & {
  output: T['Output'];
};

// Execute Action async
export const ExecuteAsyncActionDataWSchema = ExecuteActionDataWSchema.extend({
  retryOptions: z.object({
    maxAttempts: z.number().int(),
    delayMs: z.number().int(),
  }),
});
export type ExecuteAsyncActionDataWDto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteAsyncActionDataWSchema
> & {
  actionId: T['Id'];
  input: T['Input'];
};
