import {Card, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import Title from '@cohort/components-xps/components/Title';
import type {ActivatedShopifyDiscountAccessData} from '@cohort/shared/apps/shopify/perks/discount';
import {
  ActivatedShopifyDiscountAccessDataSchema,
  ShopifyDiscountAccessDataSchema,
} from '@cohort/shared/apps/shopify/perks/discount';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import Button from '@cohort/wallet/components/button/Button';
import Subtitle from '@cohort/wallet/components/Subtitle';
import {useActivatePerkAccess} from '@cohort/wallet/hooks/api/PerkAccesses';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {ClipboardText} from '@phosphor-icons/react';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ActiveDiscountComponentProps = PerkUsageComponentProps & {
  data: ActivatedShopifyDiscountAccessData;
};

const ActiveDiscountComponent: React.FC<ActiveDiscountComponentProps> = ({
  perkAccess,
  perk,
  onClose,
  data,
}) => {
  const {discountUrl, redeemCode} = data;
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'perkIntegrations.discount.perkUsageComponent',
  });
  const [copied, setCopied] = useState(false);

  const onGotoShop = useCallback(() => {
    window.open(discountUrl, '_blank', 'noopener,noreferrer');
  }, [discountUrl]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(redeemCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  }, [redeemCode]);

  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center gap-2 md:flex-row md:justify-start">
        <Card onClick={handleCopy} className="flex w-fit cursor-pointer items-center gap-2">
          <CardTitleSecondary>{redeemCode}</CardTitleSecondary>
          <ClipboardText size={16} />
        </Card>
        {copied && <p>{t('copied')}</p>}
      </div>
      <div className="mt-6 flex flex-col gap-4 md:flex-row">
        <Button
          variant="primary"
          onClick={onGotoShop}
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'visitShop',
              perkAccessId: perkAccess.id,
              perkId: perk.id,
            },
          }}
        >
          {t('openShop')}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'close',
              perkAccessId: perkAccess.id,
              perkId: perk.id,
            },
          }}
        >
          {t('close')}
        </Button>
      </div>
    </Fragment>
  );
};

export const ShopifyDiscountPerkUsageComponent: React.FC<PerkUsageComponentProps> = props => {
  const {perk, perkAccess} = props;
  const [data, setData] = useState(ShopifyDiscountAccessDataSchema.parse(props.perkAccess.data));
  const merchant = useMerchantContext();
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'perkIntegrations.discount.perkUsageComponent',
  });

  const needsActivation =
    ActivatedShopifyDiscountAccessDataSchema.safeParse(data).success === false;

  const {data: activatedPerkAccess} = useActivatePerkAccess(perkAccess.id, needsActivation);

  useEffect(() => {
    if (activatedPerkAccess !== undefined) {
      setData(ShopifyDiscountAccessDataSchema.parse(activatedPerkAccess.data));
    }
  }, [activatedPerkAccess]);

  return (
    <div className="flex flex-col">
      <div className="gap-y-2 pb-7">
        <Title testId="use-shopify-discount-perk-nft-picker-title">{perk.displayName}</Title>
        <Subtitle
          className="text-center md:text-left"
          testId="use-shopify-discount-perk-nft-picker-subtitle"
        >
          {needsActivation ? t('subtitleActivating') : t('subtitle', {brand: merchant.name})}
        </Subtitle>
      </div>
      {needsActivation ? (
        <div className="relative my-4 flex justify-center md:justify-start">
          <Spinner size={20} />
        </div>
      ) : (
        <ActiveDiscountComponent {...props} data={data as ActivatedShopifyDiscountAccessData} />
      )}
    </div>
  );
};
