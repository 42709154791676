import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {Trans, useTranslation} from 'react-i18next';

const FacebookLikePageTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<FacebookLikePageTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggerIntegrations.like-page.detailsComponent',
  });

  const url = `${FACEBOOK_URL}/${config.pageId}`;

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div>
          <p>
            <Trans
              i18nKey="triggerIntegrations.like-page.detailsComponent.rulesContent"
              ns="app-facebook"
              components={{
                a: <a href={url} target="_blank" rel="noreferrer" />,
              }}
              values={{
                pageName: config.pageName,
              }}
            />
          </p>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default FacebookLikePageTriggerIntegrationDetailsComponent;
