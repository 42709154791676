import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {authErrors, CohortError} from '@cohort/shared/schema/common/errors';
import {asString} from '@cohort/shared/typeUtils';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {logoutUser} from '@cohort/wallet/lib/Authentication';
import type {Endpoint} from '@cohort/wallet/lib/Endpoints';
import {getAmplitudeSessionId, getTraceId, trackError} from '@cohort/wallet/lib/Tracking';
import type {AxiosInstance, CancelTokenSource} from 'axios';
import axios from 'axios';
import {useCallback, useRef} from 'react';
import {getI18n} from 'react-i18next';
import {ZodError} from 'zod';

const COHORT_ENV = import.meta.env.COHORT_ENV;

export enum Status {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  NotFound = 404,
  CohortError = 417,
  InternalServerError = 500,
}

export class ApiError extends Error {
  public message!: string;
  public status: number;

  public constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'ApiError';
  }
}

export class DataValidationError extends Error {
  public message!: string;

  public constructor(message: string) {
    super(message);
    this.name = 'DataValidationError';
  }
}

export async function makeApiCall<T>(client: AxiosInstance, endpoint: Endpoint<T>): Promise<T> {
  const {parser, ...requestConf} = endpoint;
  const res = await client.request(requestConf);

  if (res.status < Status.BadRequest) {
    if (parser) {
      try {
        const parsedData = parser(res.data);

        return parsedData;
      } catch (err: unknown) {
        if (err instanceof ZodError) {
          if (COHORT_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(err.errors);
          }
        }
        trackError(err);
        throw new DataValidationError(defaultNetworkErrorMessage);
      }
    }
    return res.data;
  }
  if (res.status === Status.CohortError) {
    const data = res.data as {
      code?: CohortErrorCode;
      message?: string;
      context?: Record<string, unknown>;
    };

    if (data.code === undefined) {
      throw new ApiError(defaultNetworkErrorMessage, res.status);
    }
    if (authErrors.includes(data.code)) {
      await logoutUser();
    }
    throw new CohortError(data.code, data.context);
  }
  const errMessage = asString(res.data.message);

  if (res.status === Status.InternalServerError || errMessage === undefined) {
    throw new ApiError(defaultNetworkErrorMessage, res.status);
  }
  throw new ApiError(errMessage, res.status);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useApi = <T, K extends any[]>(
  endpoint: (...params: K) => Endpoint<T>
): ((...params: K) => Promise<T>) => {
  const source = useRef<CancelTokenSource | undefined>(undefined);

  const getClient = useCallback(async (): Promise<AxiosInstance> => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'Accept-Language': getI18n().language,
      'Cohort-Trace-Id': getTraceId(),
      'Amplitude-Session-Id': getAmplitudeSessionId()?.toString() ?? '',
    };
    const fbUser = useUserStore.getState().firebaseUser;

    if (fbUser) {
      const bearer = await fbUser.getIdToken();
      headers.Authorization = `Bearer ${bearer}`;
    }
    return axios.create({
      baseURL: import.meta.env.COHORT_API_URL,
      headers,
      validateStatus: null,
      cancelToken: source.current?.token,
    });
  }, []);

  const call = useCallback(
    async (...args: K) => {
      const client = await getClient();
      const response = makeApiCall(client, endpoint(...args));
      return response;
    },
    [endpoint, getClient]
  );

  return call;
};
