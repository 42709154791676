import type {InstagramCloseFriendsListPerkIntegrationStruct} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import {InstagramCloseFriendsListPerkIntegrationSpec} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import {InstagramCloseFriendsListPerkUsageComponent} from '@cohort/wallet/apps/instagram/perkIntegrations/closeFriendsList/PerkUsageComponent';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';

export const InstagramCloseFriendsListPerkIntegration: PerkIntegration<InstagramCloseFriendsListPerkIntegrationStruct> =
  {
    spec: InstagramCloseFriendsListPerkIntegrationSpec,
    usageComponent: InstagramCloseFriendsListPerkUsageComponent,
  };
