import type {DiscordPrivateAccessPerkIntegrationStruct} from '@cohort/shared/apps/discord/perks/privateAccess';
import {DiscordPrivateAccessPerkIntegrationSpec} from '@cohort/shared/apps/discord/perks/privateAccess';
import {DiscordPrivateAccessPerkUsageComponent} from '@cohort/wallet/apps/discord/perkIntegrations/privateAccess/PerkUsageComponent';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';

export const DiscordPrivateAccessPerkIntegration: PerkIntegration<DiscordPrivateAccessPerkIntegrationStruct> =
  {
    spec: DiscordPrivateAccessPerkIntegrationSpec,
    usageComponent: DiscordPrivateAccessPerkUsageComponent,
  };
