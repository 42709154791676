import type {GarminConnectBeActiveTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {GarminConnectBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import GarminConnectBeActiveTriggerIntegrationDetailComponent from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/beActive/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const GarminConnectBeActiveTriggerIntegration: TriggerIntegration<GarminConnectBeActiveTriggerStruct> =
  {
    spec: GarminConnectBeActiveTriggerIntegrationSpec,
    detailsComponent: GarminConnectBeActiveTriggerIntegrationDetailComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
