import MerchantContext from '@cohort/wallet/components/contexts/MerchantContext';
import type {MerchantInitWDto} from '@cohort/wallet-schemas/merchant';
import {useContext} from 'react';

export const useMerchantContext = (): MerchantInitWDto => {
  const context = useContext(MerchantContext);

  if (context === null) {
    throw new Error('useMerchantContext must be used within a MerchantContextProvider');
  }
  return context;
};
