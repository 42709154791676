import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import {SpotifyFollowUserTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followUser';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const SpotifyFollowUserTriggerIntegration: TriggerIntegration<SpotifyFollowUserTriggerStruct> =
  {
    spec: SpotifyFollowUserTriggerSpec,
    detailsComponent: null,
    actionCtaComponent: null,
    usageComponent: null,
  };
