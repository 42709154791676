import type {Currency} from '@cohort/shared/schema/common/currency';
import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

type CurrencyStore = {
  currency: Currency | null | undefined;
  setCurrency: (currency: Currency | null) => void;
};

export const useCurrencyStore = create<CurrencyStore>()(
  persist(
    set => ({
      currency: undefined,
      setCurrency: currency => set({currency}),
    }),
    {
      name: 'currency',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
