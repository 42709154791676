import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import type {UseFormRegisterReturn} from 'react-hook-form';

type DefaultInput = JSX.IntrinsicElements['input'];

export type InputProps = {
  width?: string;
  testId?: string;
  classes?: string;
  register?: UseFormRegisterReturn;
  label?: string;
  mandatory?: boolean;
  error?: JSX.Element;
};

export const Input: React.FC<DefaultInput & InputProps> = inputProps => {
  const {testId, classes, register, label, mandatory, width, error, ...rest} = inputProps;

  return (
    <div className={cn('flex w-full flex-col', width)}>
      {label && (
        <label className="mb-1 text-sm">
          {label}
          {mandatory && <span>*</span>}
        </label>
      )}
      <input
        data-testid={testId}
        {...rest}
        {...register} // Register needs to be placed after inputProps to avoid overriding the input's properties.
        className={cn(
          'duration-50 block w-full rounded-md bg-[--xps-input-background-color] transition ease-in-out [border-color:--xps-input-border-color] focus:ring-[--xps-input-active-border-color] focus:[border-color:--xps-input-active-border-color] sm:text-sm',
          classes
        )}
        style={{
          borderRadius: 'var(--xps-input-border-radius)',
        }}
      />
      {error}
    </div>
  );
};
