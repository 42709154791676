import TrackableExternalLink from '@cohort/wallet/components/tracking/TrackableExternalLink';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {getCohortPrivacyUrl, getCohortTosUrl} from '@cohort/wallet/lib/Utils';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const Footer = (): React.ReactElement => {
  const {t} = useTranslation('components', {keyPrefix: 'footer'});
  const {accentColor} = useThemeContext();

  const navigation = [
    {
      name: t('linkPrivacy'),
      href: getCohortPrivacyUrl(),
      tracking: {
        namespace: 'footer.privacy',
      },
    },
    {
      name: t('linkTerms'),
      href: getCohortTosUrl(),
      tracking: {
        namespace: 'footer.terms',
      },
    },
  ];

  return (
    <footer className="flex w-full justify-between gap-4 border-t px-4 py-6 md:px-0">
      {navigation.map(item => (
        <TrackableExternalLink
          key={item.name}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm no-underline"
          style={{color: accentColor}}
          tracking={item.tracking as TrackingConfig}
        >
          {item.name}
        </TrackableExternalLink>
      ))}
    </footer>
  );
};
Footer.displayName = 'FooterInPage';
