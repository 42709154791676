import Title from '@cohort/components-xps/components/Title';
import Button from '@cohort/wallet/components/button/Button';
import ModalOrDrawer from '@cohort/wallet/components/modals/ModalOrDrawer';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {UserApi} from '@cohort/wallet/lib/Endpoints';
import {Trans, useTranslation} from 'react-i18next';

type ManageDataModalProps = {
  onClose: () => void;
};

const ManageDataModal: React.FC<ManageDataModalProps> = ({onClose}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.auth.manageDataModal'});
  const deleteUser = useApi(UserApi.deleteUser);

  const {mutate, isLoading} = useCohortMutation({
    mutationFn: async () => deleteUser(),
    onSuccess: () => {
      localStorage.clear();
      window.location.reload();
    },
  });

  return (
    <ModalOrDrawer
      tracking={{namespace: 'modals.manageData'}}
      onClose={onClose}
      modalProps={{width: 500}}
    >
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-4">
          <Title>{t('title')}</Title>
          <p>
            <Trans
              i18nKey="modals.auth.manageDataModal.content"
              ns="components"
              components={{
                br: <span className="h-2" />,
              }}
            />
          </p>
        </div>

        <div className="flex flex-col space-y-4">
          <Button
            variant="primary"
            type="button"
            tracking={{
              namespace: 'modals.manageData.submit',
            }}
            onClick={() => mutate()}
            loading={isLoading}
          >
            {t('buttonDelete')}
          </Button>
          <Button
            variant="secondary"
            type="button"
            tracking={{
              namespace: 'modals.challenges.manageData.close',
            }}
            onClick={onClose}
          >
            {t('buttonCancel')}
          </Button>
        </div>
      </div>
    </ModalOrDrawer>
  );
};

export default ManageDataModal;
