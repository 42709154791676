import {getYoutubeVideoUrl} from '@cohort/shared/apps/youtube/common';
import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {useTranslation} from 'react-i18next';

const YoutubeLikeTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<YoutubeLikeTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'triggerIntegrations.like.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div className="flex flex-wrap items-center gap-1">
          <p>{t('rulesContent')}</p>
          <p>
            <a href={getYoutubeVideoUrl(config.videoId)} target="_blank" rel="noreferrer">
              {getYoutubeVideoUrl(config.videoId)}
            </a>
          </p>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default YoutubeLikeTriggerIntegrationDetailsComponent;
