import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {getMerchantCustomStyleSheetUrl} from '@cohort/shared-frontend/utils/merchant';
import type {Theme} from '@cohort/wallet/components/contexts/ThemeContext';
import ThemeContext from '@cohort/wallet/components/contexts/ThemeContext';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import {useEffect, useState} from 'react';
import {useMatch} from 'react-router-dom';

const useTheme = (): Theme => {
  const merchant = useMerchantContext();
  const [backgroundColor, setBackgroundColor] = useState(
    merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR
  );
  const [accentColor, setAccentColor] = useState(merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR);
  const [hasDarkBg] = useState(isDark(backgroundColor));
  const [hasDarkAccent, setHasDarkAccent] = useState(isDark(accentColor));
  const [themeLoaded, setThemeLoaded] = useState(false);
  const isStore = useMatch('/store/*');
  const [wasOnStore, setWasOnStore] = useState(isStore !== null);

  const setCampaignTheme = (campaign: CampaignStoreWDto): void => {
    const root = document.documentElement;

    if (campaign.store.backgroundColorCode) {
      const hasDarkBg = isDark(campaign.store.backgroundColorCode);

      root.style.setProperty('--xps-background-color', campaign.store.backgroundColorCode);
      root.classList.remove('light', 'dark');
      root.classList.add(hasDarkBg ? 'dark' : 'light');
    }
    if (campaign.store.accentColorCode) {
      const hasDarkAccent = isDark(campaign.store.accentColorCode);

      root.style.setProperty('--xps-accent-color', campaign.store.accentColorCode);
      setHasDarkAccent(hasDarkAccent);
    }
    setBackgroundColor(campaign.store.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR);
    setAccentColor(campaign.store.accentColorCode ?? DEFAULT_ACCENT_COLOR);
  };

  useEffect(() => {
    merchant.fonts.forEach(font => {
      const link = document.createElement('link');

      link.rel = 'stylesheet';
      link.href = font.src;
      document.head.appendChild(link);
    });
  }, [merchant.fonts]);

  useEffect(() => {
    const root = document.documentElement;
    const stylesheet = getMerchantCustomStyleSheetUrl(
      merchant.id,
      import.meta.env.COHORT_FIREBASE_STORAGE_BUCKET
    );
    const link = document.createElement('link');

    root.classList.remove('light', 'dark');
    root.classList.add(hasDarkBg ? 'dark' : 'light');

    link.rel = 'stylesheet';
    link.href = stylesheet;
    link.onload = () => {
      if (
        getComputedStyle(document.documentElement).getPropertyValue('--xps-primary-btn-color') ===
        ''
      ) {
        root.style.setProperty('--xps-primary-btn-color', hasDarkAccent ? '#ffffff' : '#000000');
      }

      if (
        getComputedStyle(document.documentElement).getPropertyValue(
          '--xps-primary-btn-hover-color'
        ) === ''
      ) {
        root.style.setProperty(
          '--xps-primary-btn-hover-color',
          hasDarkAccent ? '#ffffff' : '#000000'
        );
      }
      setThemeLoaded(true);
    };
    document.head.appendChild(link);
  }, [hasDarkBg, hasDarkAccent, merchant.id]);

  useEffect(() => {
    // restore default merchant theme when leaving store
    if (!isStore && wasOnStore) {
      const root = document.documentElement;
      const baseAccentColor = merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR;
      const baseBackgroundColor = merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR;
      const hasDarkAccent = isDark(baseAccentColor);

      root.style.setProperty('--xps-background-color', baseBackgroundColor);
      root.style.setProperty('--xps-accent-color', baseAccentColor);
      root.classList.remove('light', 'dark');
      root.classList.add(hasDarkBg ? 'dark' : 'light');
      setWasOnStore(false);
      setBackgroundColor(baseBackgroundColor);
      setAccentColor(baseAccentColor);
      setHasDarkAccent(hasDarkAccent);
    }
  }, [
    isStore,
    wasOnStore,
    backgroundColor,
    accentColor,
    hasDarkBg,
    merchant.accentColorCode,
    merchant.backgroundColorCode,
  ]);

  return {
    setCampaignTheme,
    backgroundColor,
    accentColor,
    hasDarkBg,
    hasDarkAccent,
    themeLoaded,
  };
};

export const ThemeContextProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const theme = useTheme();

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
