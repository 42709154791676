import {notifyNewVersionAvailable} from '@cohort/wallet/components/toasts/Toast';
import {useAppVersion} from '@cohort/wallet/hooks/api/Version';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {useRef} from 'react';
import type {Id} from 'react-toastify';

export default function useVersionChecker(): void {
  const toastRef = useRef<Id>();
  const {hasDarkBg} = useThemeContext();

  const currentVersion = GIT_COMMIT_SHA;
  const {data: latestVersion, isFetched} = useAppVersion(
    import.meta.env.COHORT_ENV !== 'development' && toastRef.current === undefined
  );

  // It will return false if there is an error, therefore we don't want to show the toast
  if (latestVersion === false) {
    return;
  }

  if (
    isFetched &&
    toastRef.current === undefined &&
    (latestVersion === null || latestVersion !== currentVersion)
  ) {
    toastRef.current = notifyNewVersionAvailable(hasDarkBg);
  }
}
