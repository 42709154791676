import type {ShopifyAppStruct} from '@cohort/shared/apps/shopify';
import {ShopifyAppSpec} from '@cohort/shared/apps/shopify';
import type {App} from '@cohort/wallet/apps';
import {ShopifyDiscountPerkIntegration} from '@cohort/wallet/apps/shopify/perkIntegrations/discount/PerkIntegration';

const ShopifyApp: App<ShopifyAppStruct> = {
  spec: ShopifyAppSpec,
  perkIntegrations: [ShopifyDiscountPerkIntegration],
  triggerIntegrations: [],
  logo: null,
};

export default ShopifyApp;
