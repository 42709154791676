import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import ErrorPageTemplate from '@cohort/wallet/components/error-pages/ErrorPageTemplate';
import {useMerchant} from '@cohort/wallet/hooks/api/Merchants';
import {WarningOctagon} from '@phosphor-icons/react';
import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getI18n} from 'react-i18next';

const NotFoundPage: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'errorPages.notFoundPage'});
  const {data: merchant} = useMerchant();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const accentColor = merchant ? (merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR) : undefined;
  const backgroundColor = merchant
    ? (merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR)
    : undefined;

  // Load default not found page translations since it can be rendered outside the App initialization
  useEffect(() => {
    const lng = getI18n().language;
    async function loadDefaultNotFoundPageTranslations(): Promise<void> {
      const translationResource = (await import(`../../lib/locales/${lng}.json`)).default;

      getI18n().addResourceBundle(lng, 'components', translationResource.components);
      setTranslationsLoaded(true);
    }

    if (getI18n().getResource(lng, 'components', 'errorPages') === undefined) {
      loadDefaultNotFoundPageTranslations();
    } else {
      setTranslationsLoaded(true);
    }
  }, []);

  if (!translationsLoaded) {
    return <Fragment />;
  }

  return (
    <ErrorPageTemplate
      title={t('title')}
      description={t('description')}
      accentColor={accentColor}
      Icon={WarningOctagon}
      backgroundColor={backgroundColor}
      logoFileKey={merchant?.logoFileKey ?? undefined}
    />
  );
};

export default NotFoundPage;
