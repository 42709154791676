import {useParams} from 'react-router-dom';

type ChallengeSlugRoutesProps = {
  children: (challengeSlug: string) => JSX.Element;
};

const ChallengeSlugRoutes: React.FC<ChallengeSlugRoutesProps> = ({children}) => {
  const {challengeSlug} = useParams();

  if (challengeSlug === undefined) {
    throw new Error('cannot happen');
  }
  return children(challengeSlug);
};

export default ChallengeSlugRoutes;
