import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

type SubtitleProps = {
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

const Subtitle: React.FC<SubtitleProps> = ({children, testId, className}) => (
  <h3 data-testid={testId} className={cn('break-words', className)}>
    {children}
  </h3>
);

export default Subtitle;
