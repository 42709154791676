import type {CohortBeInCohortTriggerStruct} from '@cohort/shared/apps/cohort/triggers/beInCohort';
import {CohortBeInCohortTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/beInCohort';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortBeInCohortTriggerIntegration: TriggerIntegration<CohortBeInCohortTriggerStruct> =
  {
    spec: CohortBeInCohortTriggerIntegrationSpec,
    detailsComponent: null,
    actionCtaComponent: null,
    usageComponent: null,
  };
