import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import {YoutubeLikeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/like';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import YoutubeLikeTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/youtube/triggers/like/DetailsComponent';

export const YoutubeLikeTriggerIntegration: TriggerIntegration<YoutubeLikeTriggerStruct> = {
  spec: YoutubeLikeTriggerSpec,
  detailsComponent: YoutubeLikeTriggerIntegrationDetailsComponent,
  actionCtaComponent: null,
  usageComponent: null,
};
