import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {Slot} from '@radix-ui/react-slot';
import React from 'react';

type TrackableButtonProps = React.ButtonHTMLAttributes<HTMLElement | HTMLButtonElement> & {
  tracking: TrackingConfig;
  asChild?: boolean;
};

const TrackableButton = React.forwardRef<HTMLButtonElement, TrackableButtonProps>(
  ({tracking, children, asChild = false, ...props}, ref) => {
    const trackingCallback = useClickTracker(tracking.namespace, tracking.metadata);
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        onClick={e => {
          trackingCallback();
          props.onClick?.(e);
        }}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);

export default TrackableButton;
