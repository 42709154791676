import type {TikTokCommentTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import {TikTokCommentTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import TikTokCommentTiktokTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/commentTiktok/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokCommentTiktokTriggerIntegration: TriggerIntegration<TikTokCommentTiktokTriggerStruct> =
  {
    spec: TikTokCommentTiktokTriggerSpec,
    detailsComponent: null,
    actionCtaComponent: TikTokCommentTiktokTriggerActionCtaComponent,
    usageComponent: null,
  };
