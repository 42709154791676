import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

type ActionAfterLoginStore = {
  shouldDoAction: boolean;
  setShouldDoAction: (shouldDoAction: boolean) => void;
};

export const useActionAfterLoginStore = create<ActionAfterLoginStore>()(
  persist(
    set => ({
      shouldDoAction: false,
      setShouldDoAction: shouldDoAction => set({shouldDoAction}),
    }),
    {
      name: 'shouldDoAction',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
