import type {Geolocation} from '@cohort/shared/schema/common/geolocation';
import {GeolocationSchema} from '@cohort/shared/schema/common/geolocation';
import {useGeolocationStore} from '@cohort/wallet/hooks/stores/geolocation';
import {useQuery} from '@tanstack/react-query';
import {useEffect} from 'react';

export const useGeolocation = (): Geolocation | null | undefined => {
  const {geolocation, setGeolocation} = useGeolocationStore();

  const {data: fetchedGeolocationData} = useQuery({
    queryKey: ['geolocation'],
    retry: false,
    enabled: geolocation === undefined || geolocation === null,
    queryFn: async () => {
      try {
        const res = await fetch('https://proxy.cohort.xyz/geolocation', {
          mode: 'cors',
        });
        const data = await res.json();
        const parsed = GeolocationSchema.parse(data);
        return parsed;
      } catch {
        return null;
      }
    },
  });

  useEffect(() => {
    if (
      (geolocation === undefined || geolocation === null) &&
      fetchedGeolocationData !== undefined
    ) {
      setGeolocation(fetchedGeolocationData);
    }
  }, [fetchedGeolocationData, geolocation, setGeolocation]);

  return geolocation;
};
