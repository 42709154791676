import {EmailSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

function stringToBoolean(val: string): boolean {
  return JSON.parse(val.toLocaleLowerCase());
}

export const InitParamsWSchema = z.object({
  authToken: z.string().optional(), // A one-time JWT token used to authenticate the user
  customLoginUrl: z.string().optional(), // When using embeds and custom login, this is the URL of the login page to redirect to
  customLoginRedirectParameterName: z.string().optional(), // When using embeds and custom login, this is the parameter to use to hold the URL where to redirect after login
  disableLogout: z.string().transform(stringToBoolean).optional(), // Whether to hide the logout button and prevent the user from logging out
  email: EmailSchema.optional(), // A hint for the email address of the user. When in 'cohort' auth mode, this will be used to pre-fill the email field and cannot be modified
  embedded: z.string().transform(stringToBoolean).optional(), // Whether the app is embedded in an iframe
  embedEmail: EmailSchema.optional(), // The email address of the user when the app is embedded in 'custom' auth mode
  embedUrl: z.string().optional(), // The URL of the page where the app is embedded
  navbar: z.string().transform(stringToBoolean).optional(), // Whether to show the navbar
  navigationType: z.string().optional(), // The type of navigation to use, either 'burger' or 'tabbar'
});
export type InitParamsWDto = z.infer<typeof InitParamsWSchema>;
