import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TwitterFollowTriggerIntegration: TriggerIntegration<TwitterFollowTriggerStruct> = {
  spec: TwitterFollowTriggerIntegrationSpec,
  detailsComponent: null,
  actionCtaComponent: null,
  usageComponent: null,
};
