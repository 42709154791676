import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import {getChallengeTriggerIntegrationUsageRoute} from '@cohort/wallet/lib/Pages';
import {useTranslation} from 'react-i18next';

const TypeformFillFormTriggerIntegrationActionCtaComponent: React.FC<
  ActionCtaComponentProps<TypeformFillFormTriggerStruct>
> = ({triggerId, trackingData, challengeSlug, stepId}) => {
  const navigateWithTransition = useNavigateWithTransition();
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.fill-form',
  });

  return (
    <Button
      variant="primary"
      size="small"
      className="w-full md:w-auto"
      type="button"
      tracking={{
        namespace: 'challenges.detail.actionCta',
        metadata: trackingData,
      }}
      onClick={() => {
        navigateWithTransition(
          getChallengeTriggerIntegrationUsageRoute(challengeSlug, stepId, triggerId)
        );
      }}
    >
      {t('actionButton')}
    </Button>
  );
};

export default TypeformFillFormTriggerIntegrationActionCtaComponent;
