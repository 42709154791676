import {BadgeVisibilitySchema} from '@cohort/shared/schema/common/badges';
import {z} from 'zod';

export const BadgeWSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(3),
  description: z.string().min(3),
  rank: z.number().int().min(0),
  imageFileKey: z.string(),
  visibility: BadgeVisibilitySchema,
  lockedDescription: z.string().min(3).nullable(),
  owned: z.boolean(),
});
export type BadgeWDto = z.infer<typeof BadgeWSchema>;
