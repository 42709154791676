import {useParams} from 'react-router-dom';

type CampaignSlugRoutesProps = {
  children: (campaignSlug: string) => JSX.Element;
};

const CampaignSlugRoutes: React.FC<CampaignSlugRoutesProps> = ({children}) => {
  const {campaignSlug} = useParams();

  if (campaignSlug === undefined) {
    throw new Error('cannot happen');
  }
  return children(campaignSlug);
};

export default CampaignSlugRoutes;
