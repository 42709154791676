import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {Trans, useTranslation} from 'react-i18next';

const TikTokKeywordInDescriptionTriggerDetailsComponent: React.FC<
  DetailsComponentProps<TikTokKeywordInDescriptionTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-description.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div className="flex flex-row flex-wrap gap-2">
          <p>
            <Trans
              i18nKey="triggers.keyword-in-description.detailsComponent.rulesContent"
              ns="app-tiktok"
              values={{keyword: config.keyword}}
              components={{
                keyword: <span className="font-semibold text-gray-900" />,
              }}
            />
          </p>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default TikTokKeywordInDescriptionTriggerDetailsComponent;
