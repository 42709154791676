import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {InstagramLikeMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import InstagramLikeMediaTriggerActionCtaComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/likeMedia/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramLikeMediaTriggerIntegration: TriggerIntegration<InstagramLikeMediaTriggerStruct> =
  {
    spec: InstagramLikeMediaTriggerIntegrationSpec,
    detailsComponent: null,
    actionCtaComponent: InstagramLikeMediaTriggerActionCtaComponent,
    usageComponent: null,
  };
